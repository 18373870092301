import { NIBLLforEDU } from '@components/assets/svg/NIBLLforEDU';

export const CenteredLogoHeader = () => {
  return (
    <div className="flex items-center h-16 pt-24 py-4 w-full">
      <div className="container mx-auto flex justify-center">
        <NIBLLforEDU height={48} />
      </div>
    </div>
  );
};
