import { DiscountPlan, Discount, Person } from './types/pos-person';

export const findAllMatchingDiscounts: (
  productType: string,
  discountPlans: (DiscountPlan & { discounts: Discount[] })[]
) => Discount[] = (productType, discountPlans) => {
  return discountPlans.reduce((matchingDiscounts, plan) => {
    const planDiscounts = plan.discounts.filter((discount) => {
      return discount.matches.some((match) =>
        productType.toLowerCase().includes(match.toLowerCase())
      );
    });
    return [...matchingDiscounts, ...planDiscounts];
  }, [] as Discount[]);
};

export const discountValueCalculations = {
  percentage_off: (unitPrice: number, value: number) => {
    return unitPrice - (unitPrice * value) / 100;
  },
  amount_off: (unitPrice: number, value: number) => {
    return unitPrice - value * 100;
  },
} as const;

export const findBestDiscount: (
  unitPrice: number,
  discounts: Discount[]
) => Discount | undefined = (unitPrice: number, discounts: Discount[]) => {
  return discounts.reduce((bestDiscount, discount) => {
    if (
      !discountValueCalculations[
        discount.type as keyof typeof discountValueCalculations
      ]
    )
      throw new Error(`Invalid discount type: ${discount.type}`);
    const discountValue = discountValueCalculations[
      discount.type as keyof typeof discountValueCalculations
    ](unitPrice, discount.value);
    return discountValue < bestDiscount.value ? discount : bestDiscount;
  }, discounts[0]);
};

export const valueOfDiscount: (
  unitPrice: number,
  discount: Discount
) => number = (unitPrice: number, discount: Discount) => {
  if (
    !discountValueCalculations[
      discount.type as keyof typeof discountValueCalculations
    ]
  )
    throw new Error(`Invalid discount type: ${discount.type}`);
  return discountValueCalculations[
    discount.type as keyof typeof discountValueCalculations
  ](unitPrice, discount.value);
};

export const findBestDiscountValue: (
  unitPrice: number,
  discounts: Discount[]
) => number = (unitPrice: number, discounts: Discount[]) => {
  const bestDiscount = findBestDiscount(unitPrice, discounts);
  if (!bestDiscount) return unitPrice;
  return discountValueCalculations[
    bestDiscount.type as keyof typeof discountValueCalculations
  ](unitPrice, bestDiscount.value);
};

export const calculateItemPrice = (
  product: {
    price: number;
    type: string;
  },
  person: Partial<Person> & {
    discount_plans: (DiscountPlan & { discounts: Discount[] })[];
  }
): number => {
  if (person.discount_plans.length === 0) return product.price * 100;
  let unit_price = product.price * 100;
  if (person.discount_plans.length > 0) {
    const matchingDiscounts = findAllMatchingDiscounts(
      product.type,
      person.discount_plans
    );
    const bestDiscountValue = findBestDiscountValue(
      product.price * 100,
      matchingDiscounts || []
    );

    unit_price = bestDiscountValue;
  }
  return unit_price;
};
