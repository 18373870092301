import { Dispatch, SetStateAction, useState } from 'react';
import { useOnClickOutside } from '../../util/hooks/use-on-click-outside';

export interface UnsavedChangesModalConfig {
  onUnsavedChanges: (e: MouseEvent | TouchEvent) => void;
  containerQuerySelector?: string;
  clickOutsideQuerySelector?: string;
  allowClickOutsideQuerySelector?: string;
}

export interface UnsavedChangesModalState {
  isOpen: boolean;
  mouseEvent: MouseEvent | TouchEvent | null;
}

export const useUnsavedChangesModal: (
  formId: string,
  config: boolean | UnsavedChangesModalConfig
) => [
  UnsavedChangesModalState,
  Dispatch<SetStateAction<UnsavedChangesModalState>>
] = (formId, config) => {
  const [modalState, setModalState] = useState<UnsavedChangesModalState>({
    isOpen: false,
    mouseEvent: null,
  });

  const defaultUnsavedChangesDialogConfig = {
    onUnsavedChanges: (e: MouseEvent | TouchEvent) => {
      setModalState({
        isOpen: true,
        mouseEvent: e,
      });
    },
    containerQuerySelector: `#${formId}`,
    clickOutsideQuerySelector: 'button, a, [onclick]',
    allowClickOutsideQuerySelector: '.confirmation-modal, [role="dialog"]',
  };

  let unsavedChangesConfig: UnsavedChangesModalConfig =
    defaultUnsavedChangesDialogConfig;

  if (typeof config !== 'boolean') {
    unsavedChangesConfig = {
      ...unsavedChangesConfig,
      ...config,
    };
  } else if (config === false) {
    delete unsavedChangesConfig.containerQuerySelector;
  }

  useOnClickOutside(
    unsavedChangesConfig.onUnsavedChanges,
    unsavedChangesConfig.containerQuerySelector,
    unsavedChangesConfig.clickOutsideQuerySelector,
    unsavedChangesConfig.allowClickOutsideQuerySelector
  );

  return [modalState, setModalState];
};
