import { forwardRef } from 'react';
import { Field, SelectFieldProps } from './Field';
import { Select } from '../Inputs/Select';

export const FieldSelect = forwardRef<HTMLInputElement, SelectFieldProps>(
  (props, ref) => (
    <Field
      type="select"
      inputComponent={(props) => (
        <Select ref={ref} {...(props as SelectFieldProps)} />
      )}
      {...props}
    />
  )
);
