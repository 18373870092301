export const PortionSizes = ['SMALL', 'MEDIUM', 'LARGE'] as const;
export type PortionSize = (typeof PortionSizes)[number];

export const DefaultPortionSizeNameMap = {
  SMALL: 'Happy Belly - $7.50/meal, 2.5oz protein',
  MEDIUM: 'Achieve - $11/meal, 4oz protein',
  LARGE: 'Pro-Cut - $15/meal, 7oz protein',
} as const;

export const PiperPortionSizeNameMap = {
  SMALL: 'Happy Belly — $8/meal, 2.5oz protein',
} as const;

export const aHaPortionSizeNameMap = {
  SMALL: 'Happy Belly — $8/meal, 2.5oz protein',
  MEDIUM: 'Achieve — $10/meal, 4oz protein',
  LARGE: 'Pro-Cut — $14/meal, 7oz protein',
} as const;

export const DefaultEmployeePortionSizeNameMap = {
  SMALL: '',
  MEDIUM: 'Achieve - $7.50/meal, 4oz protein',
  LARGE: 'Pro-Cut - $11.50/meal, 10oz protein',
} as const;

export const RedAppleEmployeePortionSizeNameMap = {
  MEDIUM: 'Achieve - $7.50/meal, 4oz protein',
  LARGE: 'Pro-Cut - $11.50/meal, 10oz protein',
} as const;

export const PiperEmployeePortionSizeNameMap = {
  MEDIUM: 'Employee Achieve — $11/meal, 4oz protein',
} as const;

export const aHaEmployeePortionSizeNameMap = {
  MEDIUM: 'Employee Achieve — $7/meal, 4oz protein',
} as const;

export const OCathletesPortionSizeNameMap = {
  LARGE: 'Pro-Cut - $16/meal, 7oz protein',
} as const;

export const EmployeePortionSizeNameMapByLocation: Record<number, Partial<Record<PortionSize, string>>> = {
  0: {
    // Default
    MEDIUM: DefaultEmployeePortionSizeNameMap.MEDIUM,
    LARGE: DefaultEmployeePortionSizeNameMap.LARGE,
  },
  603: {
    // Fairmont SJC
    MEDIUM: DefaultEmployeePortionSizeNameMap.MEDIUM,
    LARGE: DefaultEmployeePortionSizeNameMap.LARGE,
  },
  632: {
    // Fairmont Anaheim Hills
    MEDIUM: DefaultEmployeePortionSizeNameMap.MEDIUM,
    LARGE: DefaultEmployeePortionSizeNameMap.LARGE,
  },
  628: {
    // Fairmont Historic Anaheim
    MEDIUM: DefaultEmployeePortionSizeNameMap.MEDIUM,
    LARGE: DefaultEmployeePortionSizeNameMap.LARGE,
  },
  616: {
    // Fairmont Prep High School
    MEDIUM: DefaultEmployeePortionSizeNameMap.MEDIUM,
    LARGE: DefaultEmployeePortionSizeNameMap.LARGE,
  },
  653: {
    // Fairmont Prep Boarding
    MEDIUM: DefaultEmployeePortionSizeNameMap.MEDIUM,
    LARGE: DefaultEmployeePortionSizeNameMap.LARGE,
  },
  663: {
    // Piper dev
    MEDIUM: PiperEmployeePortionSizeNameMap.MEDIUM,
  },
  703: {
    // Piper
    MEDIUM: PiperEmployeePortionSizeNameMap.MEDIUM,
  },
  664: {
    // aHa dev
    MEDIUM: aHaEmployeePortionSizeNameMap.MEDIUM,
  },
  704: {
    // aHa
    MEDIUM: aHaEmployeePortionSizeNameMap.MEDIUM,
  },
  668: {
    // Red Apple
    MEDIUM: RedAppleEmployeePortionSizeNameMap.MEDIUM,
    LARGE: RedAppleEmployeePortionSizeNameMap.LARGE,
  },
  662: {
    // Red Apple Dev
    MEDIUM: RedAppleEmployeePortionSizeNameMap.MEDIUM,
    LARGE: RedAppleEmployeePortionSizeNameMap.LARGE,
  },
  665: {
    // OCathletes dev
    LARGE: OCathletesPortionSizeNameMap.LARGE,
  },
  715: {
    // OCathletes
    LARGE: OCathletesPortionSizeNameMap.LARGE,
  },
};

export const PortionSizeNameMapByLocation: Record<number, Partial<Record<PortionSize, string>>> = {
  0: {
    // Default
    SMALL: DefaultPortionSizeNameMap.SMALL,
    MEDIUM: DefaultPortionSizeNameMap.MEDIUM,
    LARGE: DefaultPortionSizeNameMap.LARGE,
  },
  603: {
    // Fairmont SJC
    SMALL: DefaultPortionSizeNameMap.SMALL,
    MEDIUM: DefaultPortionSizeNameMap.MEDIUM,
    LARGE: DefaultPortionSizeNameMap.LARGE,
  },
  632: {
    // Fairmont Anaheim Hills
    SMALL: DefaultPortionSizeNameMap.SMALL,
    MEDIUM: DefaultPortionSizeNameMap.MEDIUM,
    LARGE: DefaultPortionSizeNameMap.LARGE,
  },
  628: {
    // Fairmont Historic Anaheim
    SMALL: DefaultPortionSizeNameMap.SMALL,
    MEDIUM: DefaultPortionSizeNameMap.MEDIUM,
    LARGE: DefaultPortionSizeNameMap.LARGE,
  },
  616: {
    // Fairmont Prep High School
    MEDIUM: DefaultPortionSizeNameMap.MEDIUM,
    LARGE: DefaultPortionSizeNameMap.LARGE,
  },
  653: {
    // Fairmont Prep Boarding
    SMALL: DefaultPortionSizeNameMap.SMALL,
    MEDIUM: DefaultPortionSizeNameMap.MEDIUM,
    LARGE: DefaultPortionSizeNameMap.LARGE,
  },
  663: {
    // Piper dev
    SMALL: PiperPortionSizeNameMap.SMALL,
  },
  703: {
    // Piper
    SMALL: PiperPortionSizeNameMap.SMALL,
  },
  664: {
    // aHa dev
    SMALL: aHaPortionSizeNameMap.SMALL,
    MEDIUM: aHaPortionSizeNameMap.MEDIUM,
    LARGE: aHaPortionSizeNameMap.LARGE,
  },
  704: {
    // aHa
    SMALL: aHaPortionSizeNameMap.SMALL,
    MEDIUM: aHaPortionSizeNameMap.MEDIUM,
    LARGE: aHaPortionSizeNameMap.LARGE,
  },
  668: {
    // Red Apple
    SMALL: aHaPortionSizeNameMap.SMALL,
    MEDIUM: aHaPortionSizeNameMap.MEDIUM,
    LARGE: aHaPortionSizeNameMap.LARGE,
  },
  662: {
    // Red Apple Dev
    SMALL: aHaPortionSizeNameMap.SMALL,
    MEDIUM: aHaPortionSizeNameMap.MEDIUM,
    LARGE: aHaPortionSizeNameMap.LARGE,
  },
  665: {
    // OCathletes dev
    LARGE: OCathletesPortionSizeNameMap.LARGE,
  },
  715: {
    // OCathletes
    LARGE: OCathletesPortionSizeNameMap.LARGE,
  },
};

// Infer PortionSizeLocationMap from PortionSizeNameMapByLocation
const PortionSizeLocationMap: Record<string, PortionSize[]> = Object.entries(PortionSizeNameMapByLocation).reduce(
  (acc, [locationId, sizeMap]) => {
    acc[locationId] = Object.keys(sizeMap) as PortionSize[];
    return acc;
  },
  {} as Record<string, PortionSize[]>
);

// Infer EmployeePortionSizeLocationMap from EmployeePortionSizeNameMapByLocation
const EmployeePortionSizeLocationMap: Record<string, PortionSize[]> = Object.entries(
  EmployeePortionSizeNameMapByLocation
).reduce((acc, [locationId, sizeMap]) => {
  acc[locationId] = Object.keys(sizeMap) as PortionSize[];
  return acc;
}, {} as Record<string, PortionSize[]>);

export const DefaultEmployeePortionSizes = ['MEDIUM', 'LARGE'] as const;
export const PiperEmployeePortionSizes = ['MEDIUM'] as const;
export const aHaEmployeePortionSizes = ['MEDIUM'] as const;

export const selectPortionSizesForLocation = (client_id?: number | null, type?: 'STAFF' | 'STUDENT') => {
  if (!client_id) return PortionSizes;

  if (type === 'STAFF') return EmployeePortionSizeLocationMap[client_id] || PortionSizes;
  return PortionSizeLocationMap[client_id] || PortionSizes;
};
