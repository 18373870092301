import { AllHTMLAttributes, ElementType, forwardRef } from 'react';
import { FieldWrapper } from '../FieldWrapper';
import { FieldError } from '../FieldError';
import { useCombinedFieldProps } from '../Field.helpers';
import { FieldProps } from '../Field';
import {
  InputRadioCheckbox,
  InputRadioCheckboxProps,
} from '../../Inputs/InputRadioCheckbox';
import { FieldRadioCheckboxLabel } from './FieldRadioCheckboxLabel';

export const FieldRadioCheckbox = forwardRef<
  HTMLDivElement,
  FieldProps<HTMLInputElement> & {
    icon?: ElementType;
  }
>((props, ref) => {
  const { wrapperProps, labelProps, inputProps, errorProps } =
    useCombinedFieldProps(props);

  const { inputComponent, inputRef, ...restInputProps } = inputProps;

  return (
    <FieldWrapper ref={ref} {...wrapperProps}>
      <FieldRadioCheckboxLabel {...labelProps}>
        {inputComponent ? (
          inputComponent(restInputProps as AllHTMLAttributes<HTMLInputElement>)
        ) : (
          <InputRadioCheckbox
            {...(restInputProps as InputRadioCheckboxProps)}
          />
        )}
        {props.label}
      </FieldRadioCheckboxLabel>

      <FieldError {...errorProps} />
    </FieldWrapper>
  );
});
