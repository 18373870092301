import { Allergen, DietaryPreference } from '../types/pos-person';

export const miseAllergenMap: Record<string, string | undefined> = {
  Pescatarian: 'pescatarian',
  Vegetarian: 'vegetarian',
  Vegan: 'vegan',
  Gluten: 'gluten',
  Dairy: 'dairy',
  Eggs: 'eggs',
  Nuts: undefined,
  Soy: 'soy',
  Shellfish: 'shellfish',
  'Tree Nuts': 'tree_nuts',
  Fish: 'fish',
  Mushrooms: undefined,
  Peanuts: 'peanut',
  Pork: undefined,
  Beef: undefined,
  Poultry: undefined,
  Alcohol: undefined,
  Seeds: undefined,
  'Added Sugar': undefined,
  Capsaicin: undefined,
  Coconut: 'coconut',
  Sesame: undefined,
};

export const dietPreferencesOptions = [
  { label: 'Vegetarian', value: 'vegetarian' },
  { label: 'Vegan', value: 'vegan' },
  // { label: 'Pescatarian', value: 'pescatarian' },
];

export const allergensOptions = [
  { label: 'Coconut', value: 'coconut' },
  { label: 'Dairy', value: 'dairy' },
  { label: 'Eggs', value: 'eggs' },
  { label: 'Fish', value: 'fish' },
  { label: 'Peanuts', value: 'peanut' },
  { label: 'Shellfish', value: 'shellfish' },
  { label: 'Soy', value: 'soy' },
  { label: 'Tree Nuts', value: 'tree_nuts' },
  { label: 'Gluten', value: 'gluten' },
  // { label: 'Spicy', value: 'spicy' },
];

export const selectDietaryPreferences = (
  dietary_preferences: DietaryPreference[]
) => {
  if (!dietary_preferences) return [];
  return dietPreferencesOptions.filter((a) =>
    dietary_preferences.some((p) => p.slug === a.value)
  );
};
export const selectAllergens = (allergens: Allergen[]) => {
  if (!allergens) return [];
  return allergensOptions.filter((a) =>
    allergens.some((pa) => pa.slug === a.value)
  );
};

export const selectDishAllergensAsOptions = (allergens: string[]) =>
  allergens
    .map((allergen) =>
      allergensOptions.find(
        (option) => option.value === miseAllergenMap[allergen]
      )
    )
    .filter((a) => !!(a?.value && a?.label));

export const selectDishAllergens = (allergens: string[]) =>
  allergens
    .map(
      (allergen) =>
        allergensOptions.find(
          (option) => option.value === miseAllergenMap[allergen]
        )?.value
    )
    .filter((a) => !!a);
