import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { CreateOrderBody, client } from '../../client';
import { buildOptions } from '.';

export const useCreateOrder = (
  options?: UseMutationOptions<any, null, CreateOrderBody, undefined>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: CreateOrderBody) => client.createOrder(body),
    buildOptions(queryClient, [['session']], options)
  );
};
