import { useNavigate } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@components/Tabs/Tabs';
import { useMenuProducts } from '../hooks/api/menuItems';
import type { ProductsResponse } from '../client';
import { ProductItem } from '../components/checkout/ProductItem';
import type { POSProduct } from '@components/util/types/pos-product';
import { Cart } from '../components/checkout/Cart';
import { useCart } from '../components/providers/CartProvider';
import { useSelectedLocationId } from '../hooks/selectedLocation';
import { Badge } from '@components/Badges/badge';
import { CartPersonCard } from '../components/checkout/CartPersonCard';
import { toast } from 'react-toastify';
import { useBroadcastChannel } from '../hooks/useBroadcastChannel';
import { useSession } from '../hooks/api/session';
import { useEffect } from 'react';

type ProductType = ProductsResponse['products'][0]['type'];

export const Checkout = () => {
  const navigate = useNavigate();
  const { cart, addProduct, clearCart } = useCart();
  const { selectedLocationId } = useSelectedLocationId();
  const { session, isLoading: sessionLoading, isRefetching: sessionRefetching } = useSession();
  const { products } = useMenuProducts({ location_id: selectedLocationId });

  useBroadcastChannel('square-order', async (e) => {
    if (e.data === 'success') {
      clearCart();
      toast.success('Order saved successfully!');
      navigate('/');
    }
  });

  useEffect(() => {
    if (!session && !sessionLoading && !sessionRefetching) navigate('/login');
  }, [session, sessionLoading, sessionRefetching, navigate]);

  const person = cart.person;

  const types = products.reduce(
    (acc, product) => {
      if (!acc.find((type) => type?.id === product.type?.id)) {
        if (!product.type) return acc;
        acc.push({ ...product.type, products: [product] });
      } else {
        const type = acc.find((category) => category?.id === product.type.id);
        if (type) {
          type.products.push(product);
        }
      }
      return acc;
    },
    [] as (ProductType & {
      products: POSProduct[];
    })[]
  );

  if (!types.length) return <p>There are no products available for this location on this day, please call for help.</p>;

  const defaultType = types.find((type) => type.name.toLowerCase().includes(person?.portion_sizing.toLowerCase()));

  const defaultTab = defaultType ? defaultType.id.toString() : types[0].id.toString();

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-4 flex flex-col gap-4 md:max-h-[calc(100vh-76px)] overflow-y-auto pb-8">
        <CartPersonCard className="sticky top-0 z-10" />

        <Cart
          person={person}
          items={cart.items}
          orderSuccess={() => {
            toast.success('Order saved successfully!');
            clearCart();
            navigate('/');
          }}
        />
      </div>
      <div className="col-span-12 md:col-span-8">
        <Tabs defaultValue={defaultTab}>
          <TabsList className="w-full overflow-x-auto">
            {types.map((category) => {
              if (!category) return null;
              const inCartCount = cart.items.filter((cartItem) => cartItem.product?.type?.id === category.id).length;
              return (
                <TabsTrigger key={category.id} value={category.id.toString()}>
                  {category.name} {inCartCount > 0 && <Badge variant="outline">{inCartCount}</Badge>}
                </TabsTrigger>
              );
            })}
          </TabsList>
          {types.map((category) => {
            if (!category) return null;

            return (
              <TabsContent key={category.id} value={category.id.toString()}>
                <div className="overflow-hidden md:max-h-[calc(100vh-160px)] overflow-y-auto">
                  <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    {category.products.map((product, index) => (
                      <ProductItem
                        key={product.id}
                        product={product}
                        cart={cart}
                        onAddItem={addProduct}
                        index={index}
                      />
                    ))}
                  </div>
                </div>
              </TabsContent>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};
