import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import { Button } from '../Buttons/Button';
import { FieldSelect } from '@components/Fields/FieldSelect';
import { cn } from '../../util';

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  totalRecords: number;
  className?: string;
}

export function DataTablePagination<TData>({
  table,
  totalRecords,
  className,
}: DataTablePaginationProps<TData>) {
  const pageCount = Math.ceil(
    totalRecords / table.getState().pagination.pageSize
  );
  const canGoToNextPage =
    (table.getState().pagination.pageIndex + 1) *
      table.getState().pagination.pageSize <
    totalRecords;

  const canGoToPreviousPage = table.getState().pagination.pageIndex > 0;

  if (totalRecords === 0) return null;
  return (
    <div
      className={cn(
        'flex flex-wrap gap-2 items-center justify-between py-2 px-4 bg-white rounded-md border',
        className
      )}
    >
      <div className="justify-end flex flex-wrap items-center gap-x-3 gap-y-1">
        <FieldSelect
          className="w-40 text-xs -ml-2"
          name="pageSize"
          value={table.getState().pagination.pageSize.toString()}
          options={[
            { label: '10 rows/page', value: '10' },
            { label: '20 rows/page', value: '20' },
            { label: '50 rows/page', value: '50' },
            { label: '100 rows/page', value: '100' },
          ]}
          menuPlacement="top"
          placeholder="Rows per page"
          onChange={(value) => {
            value && table.setPageSize(Number(value));
          }}
        />

        <div className="flex-1 text-sm text-muted-foreground min-w-[100px]">
          {totalRecords} total
          {/* {table.getFilteredSelectedRowModel().rows.length} of {totalRecords}{' '}
        row(s) selected. */}
        </div>
      </div>
      <div className="flex-1 flex items-end sm:items-center justify-between sm:justify-end space-x-6 lg:space-x-8">
        <div className="flex flex-wrap align-start gap-x-4 gap-y-1 justify-end">
          <div className="flex items-center justify-end sm:justify-start text-xs font-medium">
            Page {table.getState().pagination.pageIndex + 1} of {pageCount}
          </div>
          <div className="flex items-center gap-2 justify-end">
            <Button
              type="button"
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(0)}
              disabled={!canGoToPreviousPage}
            >
              <span className="sr-only">Go to first page</span>
              <DoubleArrowLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              type="button"
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.previousPage()}
              disabled={!canGoToPreviousPage}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              type="button"
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.nextPage()}
              disabled={!canGoToNextPage}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
            <Button
              type="button"
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(pageCount - 1)}
              disabled={!canGoToNextPage}
            >
              <span className="sr-only">Go to last page</span>
              <DoubleArrowRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
