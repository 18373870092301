import { getFormEncType, getFormMethod } from '@conform-to/dom';
import {
  FormConfig,
  Submission,
  isFieldElement,
  useForm as useConformForm,
} from '@conform-to/react';
import { getFieldsetConstraint, parse } from '@conform-to/zod';
import { Slot } from '@radix-ui/react-slot';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import type { FC, FormEvent, PropsWithChildren, ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { ZodType, z } from 'zod';
import { ConfirmationModal } from '../Modals/ConfirmationModal';
import { selectFormDataFromFormElement } from './Form.helpers';
import {
  UnsavedChangesModalConfig,
  useUnsavedChangesModal,
} from './useUnsavedChangesModal';

export interface FormContext<T = Record<string, any>> {
  form: ReturnType<typeof useConformForm>[0];
  fields: ReturnType<typeof useConformForm>[1];
  initialValues?: Partial<T>;
  formValues: Partial<T>;
  updateFormValues: (values: T, replace?: boolean) => void;
  isDirty: boolean;
  resetForm: (payload?: T) => void;
}

export interface GlobalFormContextValue {
  forms: Record<string, FormContext<any>>;
  registerForm: <T = Record<string, any>>(
    id: string,
    formContext: FormContext<T>
  ) => void;
  unregisterForm: (id: string) => void;
  setGlobalFormValues: <T = Record<string, any>>(
    id: string,
    formData: T,
    reset?: boolean
  ) => void;
}

export const GlobalFormContext = createContext<GlobalFormContextValue>({
  forms: {},
  registerForm: () => {},
  unregisterForm: () => {},
  setGlobalFormValues: () => {},
});

export const GlobalFormProvider: FC<PropsWithChildren<{}>> = (props) => {
  const [forms, setForms] = useState<Record<string, FormContext<any>>>({}); // change here

  const registerForm = useCallback(
    function <T = Record<string, any>>(
      id: string,
      formContext: FormContext<T>
    ) {
      setForms((forms) => ({ ...forms, [id]: formContext }));
    },
    [] // Dependencies array. Here it's empty because setForms comes from useState and won't change.
  );

  const unregisterForm = useCallback(
    function (id: string) {
      setForms((forms) => {
        const newForms = { ...forms };
        delete newForms[id];
        return newForms;
      });
    },
    [] // Dependencies array. Again, empty because setForms won't change.
  );

  const setGlobalFormValues = function <T extends Record<string, any>>(
    id: string,
    formData: T,
    reset?: boolean
  ) {
    setForms((forms) => {
      const newForms = { ...forms };
      const existingForm = newForms[id];

      if (existingForm) {
        if (reset) existingForm.initialValues = cloneDeep(formData);
        newForms[id] = {
          ...existingForm,
          formValues: { ...formData },
          isDirty: checkIsDirty(existingForm.initialValues, formData),
        };
      }

      return newForms;
    });
  };

  const contextValue = useMemo(
    () => ({ forms, registerForm, unregisterForm, setGlobalFormValues }),
    [forms]
  );

  return (
    <GlobalFormContext.Provider
      value={contextValue as GlobalFormContextValue}
      {...props}
    />
  );
};

export function useFormContext<T = Record<string, any>>(
  id?: string
): FormContext<T> {
  const { forms } = useContext(GlobalFormContext);
  const formContext = useContext(FormContext);

  if (!id) {
    if (!formContext) {
      throw new Error(
        'FormContext is undefined, please ensure you are within a FormContext.Provider'
      );
    }
    return formContext;
  }

  const globalFormContext = useMemo(() => {
    return forms[id]
      ? (forms[id] as FormContext<T>)
      : ({
          form: {} as any,
          fields: {},
          formValues: {} as T,
          updateFormValues: () => {},
          isDirty: false,
          resetForm: () => {},
        } as FormContext<T>);
  }, [forms[id]]);

  return globalFormContext;
}

export const FormContext = createContext<FormContext<any> | null>(null);

const updateSelectEl = (el: HTMLSelectElement, value: string | undefined) => {
  if (value === undefined) {
    // find the selected option and unselect it
    el.querySelector('option:checked')?.setAttribute('selected', '');
    return;
  }

  el.value = value;
  el.querySelector(`option[value="${value}"]`)?.setAttribute(
    'selected',
    'selected'
  );
};

const updateFormElements = (
  form: HTMLFormElement | null,
  formValues: Record<string, any>
) => {
  if (!form) return;

  Array.from(form.elements || []).forEach((el) => {
    if (isFieldElement(el)) {
      const value = get(formValues, el.name);

      if (
        (el.type === 'checkbox' || el.type === 'radio') &&
        el instanceof HTMLInputElement
      ) {
        el.checked = value === el.value;
        return;
      }

      if (el instanceof HTMLSelectElement) {
        updateSelectEl(el, value);
        return;
      }

      if (value !== undefined && value !== el.value) {
        el.value = value;
      }
    }
  });
};

export const checkIsDirty = (
  initialValues?: Record<string, any>,
  formValues?: Record<string, any>
) => {
  const initialValueKeys = Object.keys(initialValues || {});
  const formValueKeys = Object.keys(formValues || {});
  const overlappingKeys = initialValueKeys.filter((key) =>
    formValueKeys.includes(key)
  );

  if (!initialValues || !formValues) return false;

  if (initialValueKeys.some((key) => !formValueKeys.includes(key))) return true;

  // Note: this currently works because if there are no initial default values, then the form values should also be empty before a change is made
  if (initialValueKeys.length === 0 && formValueKeys.length > 0) return true;

  const isDirty = overlappingKeys.some((key) => {
    const formValue = formValues[key];
    const initialValue = initialValues[key];

    if (formValue && typeof formValue === 'object') {
      if (Object.keys(formValue).length === 0 && !initialValue) {
        return false;
      }
    }
    return !isEqual(formValue, initialValue);
  });

  return isDirty;
};

export interface ConformFormProps<T extends Record<string, any>>
  extends FormConfig<
    ReturnType<typeof getFieldsetConstraint>,
    ReturnType<typeof parse>
  > {
  id?: string;
  children?: ReactNode | ((context: FormContext<T>) => ReactNode);
  validationSchema?: ZodType<any> | ((formData: T) => ZodType<any>);
  asChild?: boolean;
  className?: string;
  error?: string;
  reinitializeDefaultValues?: boolean;
  onChange?: (formData: T | null) => void;
  defaultValue?: Partial<T>;
  lastSubmission?: Submission<T>;
  onSubmit?: (
    event: FormEvent<HTMLFormElement>,
    context: {
      formData: FormData;
      submission: Submission;
      action: string;
      encType: ReturnType<typeof getFormEncType>;
      method: ReturnType<typeof getFormMethod>;
    }
  ) => void;
  unsavedChangesModal?: boolean | UnsavedChangesModalConfig;
}

export function Form<T extends Record<string, any>>({
  id,
  asChild = false,
  children,
  validationSchema,
  className,
  error,
  onChange,
  defaultValue = {} as Partial<T>,
  onSubmit,
  unsavedChangesModal = false,
  reinitializeDefaultValues = true,
  lastSubmission,
  ...props
}: ConformFormProps<T>) {
  const initialDefaultValue = useMemo(
    () => lastSubmission?.value || defaultValue,
    [lastSubmission]
  );
  const initialValues = useMemo(() => {
    if (lastSubmission) return lastSubmission.value as T;
    return defaultValue;
  }, [defaultValue, lastSubmission]);

  const [formValues, setFormValues] = useState<T | Partial<T>>(
    cloneDeep(initialDefaultValue) || ({} as Partial<T>)
  );

  const reactId = useId();
  const formId = id || `form-${reactId}`;

  // Register the form context with the global form context
  const { registerForm, unregisterForm, setGlobalFormValues } =
    useContext(GlobalFormContext);

  const schema =
    typeof validationSchema === 'function'
      ? validationSchema(formValues as T)
      : validationSchema || z.any();

  const handleFormSubmit: (
    event: FormEvent<HTMLFormElement>,
    context: {
      formData: FormData;
      submission: Submission;
      action: string;
      encType: ReturnType<typeof getFormEncType>;
      method: ReturnType<typeof getFormMethod>;
    }
  ) => void = (e, ctx) => {
    if (typeof onSubmit === 'function') {
      e.preventDefault();
      onSubmit(e, ctx);
    }
  };

  const formConfig: FormConfig<T> = {
    shouldValidate: 'onBlur',
    constraint: getFieldsetConstraint(schema),
    onValidate: ({ formData }: { formData: FormData }) => {
      const parsed = parse(formData, { schema });
      if (parsed.error) console.debug('validation error', parsed.error);
      return parsed;
    },
    defaultValue: (reinitializeDefaultValues
      ? defaultValue
      : initialDefaultValue) as any,
    onSubmit: handleFormSubmit,
    lastSubmission,
    ...props,
  };

  const [form, fields] = useConformForm<T>(formConfig);

  // Note: update values from latest submission
  useEffect(() => {
    const lastSubmissionValue = lastSubmission?.value as T | undefined;

    if (lastSubmissionValue) {
      resetForm(lastSubmissionValue);
    }
  }, [lastSubmission]);

  const isDirty = checkIsDirty(initialValues, formValues);

  const [unsavedChangesModalState, setUnsavedChangesModalState] =
    useUnsavedChangesModal(formId, isDirty ? unsavedChangesModal : false);

  const updateFormValues = (values: Partial<T>, replace?: boolean) => {
    const currentFormValues = selectFormDataFromFormElement(
      form.ref.current as HTMLFormElement,
      schema
    );

    if (replace) {
      setFormValues(values);
      setGlobalFormValues<T>(formId, values as T);
      return;
    }

    setFormValues(merge({}, currentFormValues, values));

    const updatedFormValues = merge({}, currentFormValues, values) as T;

    setGlobalFormValues<T>(formId, updatedFormValues);

    updateFormElements(form.ref.current, updatedFormValues);
  };

  const resetForm = (payload?: Partial<T>) => {
    const payloadIsT = payload
      ? Object.keys(payload).some((key) => key in initialValues)
      : false;
    const clonedPayload = payload ? cloneDeep(payload) : false;

    if (clonedPayload && payloadIsT) {
      updateFormValues(clonedPayload, true);
      setGlobalFormValues(formId, clonedPayload, true);

      form.ref.current?.reset();
      return;
    }

    const clonedInitialValues = cloneDeep(initialValues);

    updateFormValues(clonedInitialValues, true);
    setGlobalFormValues(formId, clonedInitialValues, true);
    form.ref.current?.reset();
  };

  useEffect(() => {
    if (!form.ref.current) return;
    registerForm(formId, {
      form,
      fields,
      initialValues,
      formValues,
      updateFormValues,
      isDirty,
      resetForm,
    });
    return () => {
      unregisterForm(formId);
    };
  }, [formId, form.ref.current]);

  form.error = error || form.error; // Note: this allows for form level errors to be held in the context

  const handleFormChange: React.FormEventHandler<HTMLElement> &
    React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      if (isFieldElement(e.target)) {
        const formElement = e.target.form as HTMLFormElement;
        const formData = selectFormDataFromFormElement(formElement, schema);
        if (!formData) return;
        updateFormValues(formData as T, true);
        if (typeof onChange === 'function') onChange(formData as T);
      }
    },
    [schema, onChange]
  );

  const FormComponent = asChild ? Slot : 'form';
  const contextValue = {
    form,
    fields,
    initialValues,
    formValues,
    updateFormValues,
    isDirty,
    resetForm,
  };

  return (
    <FormContext.Provider value={contextValue}>
      <FormComponent
        {...form.props}
        className={className}
        id={formId}
        onChange={handleFormChange}
      >
        {typeof children === 'function' ? children(contextValue) : children}
      </FormComponent>
      <ConfirmationModal
        title="You have unsaved changes"
        description="Are you sure you want to continue? Your changes will not be saved."
        open={unsavedChangesModalState.isOpen}
        cancelLabel="Go back"
        onOpenChange={(isOpen) =>
          setUnsavedChangesModalState((state) => ({ ...state, isOpen }))
        }
        onContinue={() => {
          const eventTarget = unsavedChangesModalState.mouseEvent?.target as
            | HTMLElement
            | undefined;
          if (!eventTarget) return;
          const closestTarget = eventTarget.closest(
            'button, a, [onclick]'
          ) as HTMLElement;

          if (closestTarget && typeof closestTarget.click !== 'function')
            return;

          closestTarget.click();
        }}
      />
    </FormContext.Provider>
  );
}
