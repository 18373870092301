import { cn } from '../../util';
import { defaultInputClasses } from './Input.helpers';
import { FieldProps } from '../Fields/Field';
import { forwardRef, type AllHTMLAttributes } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InputProps<T = HTMLElement> extends AllHTMLAttributes<T> {
  error?: FieldProps['error'];
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, error, ...props }, ref) => {
    return (
      <input
        key={props.defaultValue?.toString()}
        type={type}
        className={cn('h-12', defaultInputClasses(error), className)}
        ref={ref}
        {...props}
      />
    );
  }
);
