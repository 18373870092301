import { Button } from '@components/Buttons/Button';
import { Card } from '@components/Cards/Card';
import { Field, FormError } from '@components/Conform';
import { Form } from '@components/Conform/Form';
import { useSession, useLogin } from '../hooks/api/session';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BasicCenteredLayout } from '@components/nibll/layouts/BasicCenteredLayout';
import { email } from '@components/util/schemas';

const loginSchema = z.object({
  email,
  password: z.string().min(1, { message: 'Please enter your password' }),
});

export const Login = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const { mutate, isLoading } = useLogin({
    onSuccess: () => navigate('/'),
    onError: () => setError('Invalid email or password'),
  });
  const { session, isLoading: sessionLoading, isRefetching } = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (session && !sessionLoading && !isRefetching) navigate('/');
  }, [session, navigate]);

  if (sessionLoading) return null;

  return (
    <BasicCenteredLayout>
      <Card className="max-w-md mx-auto">
        <Card.Header>
          <Card.Title>Login</Card.Title>
        </Card.Header>

        <Form
          validationSchema={loginSchema}
          error={error}
          onSubmit={(e, context) => {
            setError(undefined);
            mutate(context.submission.value);
          }}
        >
          <Card.Content className="grid gap-4">
            <Field name="email" label="Email" />
            <Field name="password" type="password" label="Password" />
            <FormError />
          </Card.Content>
          <Card.Footer>
            <Card.Actions>
              <Button type="submit" disabled={isLoading}>
                Login
              </Button>
            </Card.Actions>
          </Card.Footer>
        </Form>
      </Card>
    </BasicCenteredLayout>
  );
};
