import { Button } from '@components/Buttons/Button';
import { useSession } from '../hooks/api/session';
import { UserNav } from './nav/UserNav';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NIBLLforEDU } from '@components/assets/svg/NIBLLforEDU';
import { BackButton } from '@components/Buttons/BackButton';
import { useSelectedLocationId } from '../hooks/selectedLocation';

export const Header = () => {
  const { session } = useSession();
  const navigate = useNavigate();
  const { selectedLocation } = useSelectedLocationId();
  const location = useLocation();

  return (
    <div className="h-16 py-4 bg-white w-full">
      <div className="container flex gap-4 items-center justify-between">
        <div className="flex gap-4">
          <BackButton
            navigate={navigate}
            location={location}
            paths={['checkout', 'device-settings']}
          />
          <NIBLLforEDU height={36} width={175} />
        </div>
        <div className="flex-1" />
        <span className="text-xs font-bold">
          {selectedLocation ? (
            `${selectedLocation.name}`
          ) : (
            <Link className="underline" to="/device-settings">
              Set Device Location
            </Link>
          )}
        </span>
        {!!session && <UserNav session={session} />}
        {!session && <Button onClick={() => navigate('/login')}>Login</Button>}
      </div>
    </div>
  );
};
