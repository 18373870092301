import { PersonCard } from '@components/Cards/PersonCard';
import { useCart } from '../providers/CartProvider';
import { Card } from '@components/Cards/Card';

export const CartPersonCard = ({ className }: { className?: string }) => {
  const {
    cart: { person },
  } = useCart();

  if (person)
    return (
      <PersonCard
        isPOS
        className={className}
        person={person}
        accountBalance={person.user.account_balance?.balance || 0}
      />
    );
  return (
    <Card className={className}>
      <Card.Header className="flex justify-between items-center">
        <Card.Title className="flex gap-2.5">Guest</Card.Title>
      </Card.Header>
      <Card.Content>Checking out as Guest</Card.Content>
    </Card>
  );
};
