import { POSProduct } from 'ui-components/src/util/types/pos-product';
import { config } from './config';
import { createOrderSchema } from '@components/util/schemas/createOrder';
import {
  AccountBalance,
  Allergen,
  LineItem,
  Location,
  Order,
  Person,
} from 'packages/database';
import qs from 'qs';
import { POSPerson } from '@components/util/types/pos-person';
import { IndividualPreorder } from '@components/util/types/preorder';

export const postJson = async <T>(path: string, body?: any): Promise<T> => {
  const fullPath = `${config.API_URL}${path}`;
  const response = await fetch(fullPath, {
    method: 'POST',
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (response.status > 299)
    throw new Error(`POST ${fullPath} returned ${response.status}`);

  return response.json();
};

export const getJson = async <T>(path: string): Promise<T> => {
  const fullPath = `${config.API_URL}${path}`;
  const response = await fetch(fullPath, {
    method: 'GET',
    credentials: 'include',
  });

  if (response.status > 299)
    throw new Error(`GET ${fullPath} returned ${response.status}`);

  return response.json();
};

export interface POSSession {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface POSSessionResponse {
  session: POSSession;
}

export interface LoginBody {
  email: string;
  password: string;
}

export interface LocationResponse {
  locations: Location[];
}

export interface PreordersResponse {
  preorders: {
    count: number;
    preorders: IndividualPreorder[];
  };
}

export interface ProductsResponse {
  products: POSProduct[];
}

export interface PersonsResponse {
  persons: POSPerson[];
}

export type CreateOrderBody = (typeof createOrderSchema)['_type'];

export const client = {
  login: (body: LoginBody) =>
    postJson<POSSessionResponse>(`/api/pos/login`, body),
  getSession: () => getJson<POSSessionResponse>(`/api/pos/session`),
  logout: () => postJson(`/api/pos/logout`),
  listLocations: () => getJson<LocationResponse>(`/api/pos/locations`),
  listPersonsByLocation: (location?: string) =>
    getJson<PersonsResponse>(`/api/pos/persons?location=${location}`),
  listPreorders: () => getJson<PreordersResponse>(`/api/pos/preorders`),
  listProducts: (params: { location_id: string; date: string }) =>
    getJson<ProductsResponse>(`/api/pos/products?${qs.stringify(params)}`),
  createOrder: (body: CreateOrderBody) =>
    postJson<
      Order & {
        items: LineItem[];
        location: Location | null;
        account_balance: AccountBalance | null;
      }
    >(`/api/pos/order`, body),
};
