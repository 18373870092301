import { conform, useInputEvent } from '@conform-to/react';
import get from 'lodash/get';
import { ElementType, forwardRef, useRef } from 'react';
import {
  FieldProps,
  Field as InnerField,
  SelectFieldProps,
} from '../Fields/Field';
import { FieldSelect as InnerFieldSelect } from '../Fields/FieldSelect';
import { FieldTextArea as InnerFieldTextArea } from '../Fields/FieldTextArea';
import { FieldRadioCheckbox } from '../Fields/RadioCheckbox/FieldRadioCheckbox';
import { useFormContext } from './Form';
import { useFieldProps } from './Form.helpers';

export const Field = forwardRef<HTMLInputElement, FieldProps>((props, ref) => {
  const fieldProps = useFieldProps(props);
  return <InnerField ref={ref} {...props} {...fieldProps} />;
});

export const FieldTextArea = forwardRef<HTMLInputElement, FieldProps>(
  (props, ref) => {
    const fieldProps = useFieldProps({ type: 'textarea', ...props });
    return <InnerFieldTextArea ref={ref} {...props} {...fieldProps} />;
  }
);

export const FieldSelect = forwardRef<HTMLInputElement, SelectFieldProps>(
  (props, ref) => {
    const { onChange, ...fieldProps } = useFieldProps(props as FieldProps);
    const { fields } = useFormContext();
    const shadowInputRef = useRef<HTMLInputElement>(null);
    const control = useInputEvent({
      ref: shadowInputRef,
    });

    const handleChange = (newValue: unknown) => {
      const isValueArray = Array.isArray(newValue);
      const value = isValueArray ? newValue.join(',') : newValue;

      if (typeof onChange === 'function') onChange(value as any);
      control.change(value as string);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (typeof props.onInputBlur === 'function') props.onInputBlur(e);
      control.blur();
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      if (typeof props.onInputFocus === 'function') props.onInputFocus(e);
      control.focus();
    };
    const field = conform.input(fields[props.name], {
      hidden: true,
    });

    return (
      <>
        <input ref={shadowInputRef} {...field} />

        <InnerFieldSelect
          key={field.value}
          ref={ref}
          {...props}
          {...fieldProps}
          name={''} // hide the select in favor of our shadow input
          onChange={handleChange}
          onInputBlur={handleBlur}
          onInputFocus={handleFocus}
        />
      </>
    );
  }
);

export const FieldRadio = forwardRef<
  HTMLInputElement,
  Omit<FieldProps, 'id'> & { id: string }
>((props, ref) => {
  const fieldProps = useFieldProps({ ...props, type: 'radio' });

  return (
    <FieldRadioCheckbox ref={ref} {...props} {...fieldProps} type="radio" />
  );
});

export const FieldCheckbox = forwardRef<
  HTMLInputElement,
  Omit<FieldProps, 'value'> & { value?: string; icon?: ElementType }
>(({ value = 'true', ...props }, ref) => {
  const fieldProps = useFieldProps({ value, ...props, type: 'checkbox' });

  return (
    <FieldRadioCheckbox
      ref={ref}
      {...props}
      {...fieldProps}
      value={value}
      type="checkbox"
    />
  );
});
