import { useLocalStorage } from 'usehooks-ts';
import { useLocations } from './api/location';

export const useSelectedLocationId = () => {
  const { locations } = useLocations();
  const [selectedLocationId, setSelectedLocationId] = useLocalStorage<string>(
    'selected-location',
    undefined
  );

  const selectedLocation = locations?.find(
    (location) => location.id === selectedLocationId
  );

  return {
    selectedLocationId,
    setSelectedLocationId,
    selectedLocation,
  };
};
