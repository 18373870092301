import { useQuery } from '@tanstack/react-query';
import { client } from '../../client';

export const useLocations = () => {
  const { data, ...rest } = useQuery(['locations'], () =>
    client.listLocations()
  );

  return {
    locations: data?.locations || [],
    ...rest,
  };
};
