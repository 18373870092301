import { FC, HTMLAttributes, ReactElement } from 'react';
import { cn } from '../../util';

export type FieldHelperTextComponent = (
  helperTextProps: Omit<FieldHelperTextProps, 'errorComponent'>
) => ReactElement<FieldHelperTextProps, FC> | null;

export interface FieldHelperTextProps extends HTMLAttributes<HTMLDivElement> {
  helperText?: string;
  helperTextComponent?: FieldHelperTextComponent;
}

export const FieldHelperText: FC<FieldHelperTextProps> = ({
  className,
  helperTextComponent,
  helperText,
  ...props
}) => {
  if (!helperText) return null;

  if (helperTextComponent) return helperTextComponent(props);

  return (
    <div
      className={cn('field__helper-text mt-2 text-sm', className)}
      {...props}
    >
      {helperText}
    </div>
  );
};
