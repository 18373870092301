import { Outlet, useNavigate } from 'react-router-dom';
import { Header } from '../components/Header';
import { CartProvider, useCart } from '../components/providers/CartProvider';
import { toast } from 'react-toastify';
import { useBroadcastChannel } from '../hooks/useBroadcastChannel';
import { useSession } from '../hooks/api/session';

export const RootLayout = () => {
  const session = useSession();
  return (
    <CartProvider>
      <div className="bg-slate-50 min-h-screen">
        <Header />
        <div className="container pt-4">
          <Outlet />
        </div>
      </div>
    </CartProvider>
  );
};
