import { ZodObject, ZodRawShape, z } from 'zod';

export const password = z
  .string()
  .min(1, { message: 'Please enter your password' })
  .min(6, { message: 'Enter at least 6 characters' });

export const confirmPassword = z
  .string()
  .min(1, { message: 'Please confirm your password' });

export const withPasswordConfirmation = <T extends ZodRawShape>(
  schema: ZodObject<T>,
  optional = false
) =>
  schema
    .extend({
      password: optional ? z.string().optional() : password,
      confirmPassword: optional ? z.string().optional() : confirmPassword,
    })
    .superRefine(({ password, confirmPassword }: any, ctx) => {
      if (!!password && !!confirmPassword && password !== confirmPassword) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Passwords do not match',
          path: ['confirmPassword'],
        });
      }
    });
