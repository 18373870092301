import { Button } from '@components/Buttons/Button';
import type { POSProduct } from '@components/util/types/pos-product';
import { type FC, useMemo } from 'react';
import { formatCurrency } from '@components/util/currency';
import { Plus } from 'lucide-react';
import { selectDishAllergensAsOptions } from '@components/util/data/allergens';
import type { AddProductOptions, Cart } from '../providers/CartProvider';
import { cn } from '@components/util/index';
import { Badge } from '@components/Badges/badge';
import { findAllMatchingDiscounts, findBestDiscount, valueOfDiscount } from '@components/util/discounts';

const gradientClasses = [
  'from-blue-50 to-blue-200',
  'from-green-50 to-green-200',
  'from-yellow-50 to-yellow-200',
  'from-red-50 to-red-200',
  'from-purple-50 to-purple-200',
  'from-pink-50 to-pink-200',
];

export const ProductItem: FC<{
  className?: string;
  product: POSProduct;
  cart: Cart;
  onAddItem: (product: POSProduct, options?: AddProductOptions) => void;
  index: number;
}> = ({ className, product, cart, onAddItem, index }) => {
  const allergenList = selectDishAllergensAsOptions(product.allergens)
    .map((a) => a.label)
    .join(', ');

  const inCartCount = (cart.items || []).filter((cartItem) => cartItem.id === product.id).length;

  const matchingDiscounts = useMemo(
    () => findAllMatchingDiscounts(product.type.name, cart.person?.discount_plans || []),
    [cart.person, product.type.name]
  );

  const bestDiscount = useMemo(
    () => findBestDiscount(product.unit_price, matchingDiscounts),
    [product.unit_price, matchingDiscounts]
  );

  if (bestDiscount) product.discount = bestDiscount;

  product.computed_price = useMemo(() => {
    if (!cart.person || !bestDiscount) return product.unit_price;
    return valueOfDiscount(product.unit_price, bestDiscount);
  }, [cart.person, product.unit_price, product.type.name]);

  const gradientClass = gradientClasses[index % gradientClasses.length];

  return (
    <Button
      variant="ghost"
      className={cn(
        'flex flex-col justify-between h-full w-full p-4 text-left bg-gradient-to-br transition-all duration-300 min-h-32',
        gradientClass,
        className
      )}
      onClick={() => onAddItem(product)}
    >
      <div className="w-full">
        <div className="font-medium mb-1 leading-tight">{product.name}</div>
        {allergenList.length > 0 && <div className="text-xs font-bold text-gray-600 mb-2">{allergenList}</div>}
      </div>
      <div className="flex justify-between items-center w-full mt-2 -mb-2">
        <span className="font-medium">{formatCurrency(product.computed_price || product.unit_price)}</span>
        <div className="flex items-center gap-2">
          {inCartCount > 0 && (
            <Badge variant="outline" className="bg-white -mr-1">
              {inCartCount}
            </Badge>
          )}
        </div>
      </div>
    </Button>
  );
};
