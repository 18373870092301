import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../util';

export const buttonVariants = {
  variant: {
    default:
      'button--default border-primary-100 text-primary-800 hover:text-primary-800 bg-primary-50 hover:bg-primary-200',
    primary:
      'button--primary text-white bg-primary-800 hover:bg-primary-900 focus:border-primary-500',
    accent:
      'button--accent bg-accent text-accent-foreground hover:bg-accent-700',
    outline:
      'button--outline border border-primary border-input bg-transparent hover:bg-slate-100 hover:text-slate-800',
    success: 'button--success bg-green-500 text-green-50 hover:bg-green-700',
    danger: 'button--desctructive bg-red-500 text-red-50 hover:bg-red-700',
    warning: 'button--warning bg-yellow-500 text-yellow-50 hover:bg-yellow-700',
    link: 'button--link text-primary underline-offset-4 underline border-none rounded-none',
    ghost: 'hover:bg-slate-200 hover:text-slate-800 border-none',
  },
  size: {
    sm: 'h-9 px-3 text-sm',
    md: 'h-12 py-[11px] px-5 text-base',
    lg: 'h-14 py-[13px] px-6 text-lg',
    icon: 'h-10 w-10',
  },
};

export const buttonVariantsClasses = cva(
  'inline-flex border items-center justify-center rounded-md text-sm font-medium active:scale-95 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      ...buttonVariants,
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
    compoundVariants: [
      {
        variant: 'link',
        size: ['sm', 'md', 'lg'],
        className: 'h-auto p-0',
      },
      {
        variant: 'ghost',
        size: ['sm', 'md', 'lg'],
        className: 'h-auto p-0',
      },
    ],
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariantsClasses> {
  asChild?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariantsClasses({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';
