import ReactSelect, {
  ActionMeta,
  GroupBase,
  InputActionMeta,
} from 'react-select';
import { forwardRef, useMemo } from 'react';
import { cn } from '../../util';
import { defaultInputClasses } from './Input.helpers';
import { InputProps } from './Input';
import { FieldProps } from '../Fields/Field';
import get from 'lodash/get';

export type OptionType = Record<string, string | number>;
export type OptionsType = Array<OptionType | GroupType>;
export type GroupType = GroupBase<OptionType>;
export type SelectValueType = OptionType | OptionsType | null | void;
//@ts-ignore
export interface SelectProps {
  className?: string;
  options?: OptionsType;
  isMulti?: boolean;
  isDisabled?: boolean;
  error?: FieldProps['error'];
  defaultValue?: string | number | readonly string[];
  value?: string | number | readonly string[];
  onChange?: (value: unknown, actionMeta: any) => void;
  onInputFocus?: InputProps['onChange'];
  onInputBlur?: InputProps['onBlur'];
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  valueKey?: string;
  labelKey?: string;
  placeholder?: string;
  isLoading?: boolean;
  controlProps?: {
    className?: string;
  };
}

const isGroup = (option: OptionType | GroupType): option is GroupType =>
  'options' in option;

const findSingleOption = (
  options: OptionsType,
  value: string | number,
  valueKey: string
): OptionType | GroupType | null => {
  for (const option of options) {
    if (isGroup(option)) {
      const found = findSingleOption(
        option.options as OptionsType,
        value,
        valueKey
      );
      if (found) return found;
    } else if (get(option, valueKey) === value) {
      return option;
    }
  }
  return null;
};

const findOptions = (
  options: OptionsType | undefined,
  value: string | number | readonly string[] | undefined,
  { isMulti, valueKey }: { isMulti?: boolean; valueKey: string }
): OptionType | GroupType | null | OptionType[] | undefined => {
  if (!options || !value) return undefined;

  const actualValue =
    typeof value === 'string' && isMulti ? value.split(',') : value;

  if (isMulti && Array.isArray(actualValue)) {
    return actualValue
      .map((val) => findSingleOption(options, val, valueKey))
      .filter((option) => option !== null) as OptionType[];
  } else {
    return findSingleOption(options, actualValue as string | number, valueKey);
  }
};

export const Select = forwardRef<any, SelectProps>(
  (
    {
      options,
      className,
      error,
      onChange,
      valueKey = 'value',
      labelKey = 'label',
      controlProps,
      ...props
    },
    ref
  ) => {
    const handleOnChange = (
      newValue: unknown,
      actionMeta: ActionMeta<unknown>
    ) => {
      if (typeof onChange === 'function') {
        if (props.isMulti && Array.isArray(newValue)) {
          onChange(
            newValue.map((v) => get(v, valueKey)),
            actionMeta
          );
        } else onChange(get(newValue, valueKey), actionMeta);
      }
    };

    const defaultValue = useMemo(
      () =>
        findOptions(options, props.defaultValue, {
          isMulti: props.isMulti,
          valueKey,
        }),
      [options, props.defaultValue, props.isMulti]
    );

    const value = useMemo(() => {
      if (defaultValue !== undefined) return undefined;
      return (
        findOptions(options, props.value, {
          isMulti: props.isMulti,
          valueKey,
        }) || '' // Note: React-Select expects an empty string when no value is selected (src: https://stackoverflow.com/questions/50412843/how-to-programmatically-clear-reset-react-select)
      );
    }, [options, props.value, props.isMulti]);

    return (
      <ReactSelect
        ref={ref}
        classNamePrefix="react-select"
        classNames={{
          control: () =>
            cn(
              'h-12 w-full focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2',
              defaultInputClasses(error),
              'p-0 pl-1 !border-input',
              className
            ),
          option: ({ isSelected }) =>
            cn('!cursor-pointer', {
              '!text-primary-900 !bg-primary-100': isSelected,
            }),
          dropdownIndicator: () => cn('!cursor-pointer'),
          clearIndicator: () => cn('!cursor-pointer'),
          input: () => cn('!cursor-text'),
          // Note: leaving this comment here for reference on which components are available to style/override
          // clearIndicator: ClearIndicatorProps<Option, IsMulti, Group>;
          // container: ContainerProps<Option, IsMulti, Group>;
          // control: ControlProps<Option, IsMulti, Group>;
          // dropdownIndicator: DropdownIndicatorProps<Option, IsMulti, Group>;
          // group: GroupProps<Option, IsMulti, Group>;
          // groupHeading: GroupHeadingProps<Option, IsMulti, Group>;
          // indicatorsContainer: IndicatorsContainerProps<Option, IsMulti, Group>;
          // indicatorSeparator: IndicatorSeparatorProps<Option, IsMulti, Group>;
          // input: InputProps<Option, IsMulti, Group>;
          // loadingIndicator: LoadingIndicatorProps<Option, IsMulti, Group>;
          // loadingMessage: NoticeProps<Option, IsMulti, Group>;
          // menu: MenuProps<Option, IsMulti, Group>;
          // menuList: MenuListProps<Option, IsMulti, Group>;
          // menuPortal: PortalStyleArgs;
          // multiValue: MultiValueProps<Option, IsMulti, Group>;
          // multiValueLabel: MultiValueProps<Option, IsMulti, Group>;
          // multiValueRemove: MultiValueProps<Option, IsMulti, Group>;
          // noOptionsMessage: NoticeProps<Option, IsMulti, Group>;
          // option: OptionProps<Option, IsMulti, Group>;
          // placeholder: PlaceholderProps<Option, IsMulti, Group>;
          // singleValue: SingleValueProps<Option, IsMulti, Group>;
          // valueContainer: ValueContainerProps<Option, IsMulti, Group>;
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'rgb(var(--color-primary-DEFAULT))',
            primary25: 'rgb(var(--color-primary-50))',
            primary50: 'rgb(var(--color-primary-300))',
            primary75: 'rgb(var(--color-primary-500))',
          },
        })}
        {...props}
        options={options}
        onChange={handleOnChange}
        defaultValue={defaultValue}
        value={value}
        getOptionLabel={(option) => get(option, labelKey)}
        getOptionValue={(option) => get(option, valueKey)}
      />
    );
  }
);
