import { FC, LabelHTMLAttributes, ReactElement } from 'react';
import { cn } from '../../util';

export interface FieldLabelComponentProps
  extends LabelHTMLAttributes<HTMLElement> {
  required?: boolean;
  optional?: boolean;
}

export type FieldLabelComponent = (
  labelProps: FieldLabelComponentProps
) => ReactElement<FieldLabelComponentProps, FC>;

export interface FieldLabelProps extends FieldLabelComponentProps {
  labelComponent?: FieldLabelComponent;
}

export const FieldLabel: FC<FieldLabelProps> = ({
  className,
  labelComponent,
  children,
  ...props
}) => {
  if (!labelComponent && !children) return null;

  if (labelComponent) return labelComponent({ className, children, ...props });

  return (
    <div className="flex justify-between items-end">
      <label
        {...props}
        className={cn(
          'field__label text-sm font-bold text-gray-700 mb-1.5 whitespace-pre-wrap',
          className
        )}
      >
        {children}
        {props.required && (
          <span className="text-red-600 relative -top-0.5 text-xs">*</span>
        )}
      </label>

      {props.optional && (
        <span className="font-normal text-gray-500 text-xs mb-1">Optional</span>
      )}
    </div>
  );
};
