import { Card } from '@components/Cards/Card';
import { FC } from 'react';
import { formatCurrency } from '@components/util/currency';
import { allergensOptions } from '@components/util/data/allergens';
import { FieldLabel } from '@components/Fields/FieldLabel';
import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '@components/Modals/ConfirmationModal';
import { POSPerson } from '@components/util/types/pos-person';
import { CartItem } from '../providers/CartProvider';
import { CartPersonCard } from './CartPersonCard';
import { Alert, AlertDescription, AlertTitle } from '@components/Alerts/Alert';

export const CheckoutConfirmationModal: FC<
  {
    person: POSPerson;
    allergenList: string[];
    cartTotal: number;
    items: CartItem[];
  } & ConfirmationModalProps
> = ({ items = [], person, allergenList, cartTotal, ...props }) => {
  const notEnoughFunds = person?.user.account_balance.balance < cartTotal;

  return (
    <ConfirmationModal
      title={`Review cart ${items.length > 0 ? `(${items.length})` : ''}`}
      {...props}
      description={
        <div className="text-foreground max-h-[calc(90vh-160px)] overflow-y-auto">
          <CartPersonCard className="mb-4" />

          {notEnoughFunds && (
            <Alert className="border-red-600 mb-2">
              <AlertTitle>
                Balance is not enough to complete this order.
              </AlertTitle>
              <AlertDescription>
                Only continue if the student doesn't have a credit card to
                charge.
              </AlertDescription>
            </Alert>
          )}

          {items.map((item) => {
            return (
              <Card key={item.id} unstyled className="border-b">
                <Card.Header className="relative !px-0 !pt-2 !pb-0.5">
                  <Card.Title className="pr-8 w-full grid">
                    <span>{item.name}</span>
                    <span className="text-base font-normal">
                      {item?.product.allergens
                        ?.map(
                          (productAllergen) =>
                            allergensOptions.find(
                              (allergen) => allergen.value === productAllergen
                            )?.label || productAllergen
                        )
                        .join(', ')}
                    </span>
                  </Card.Title>
                </Card.Header>
                <Card.Content className="!px-0 !pb-2">
                  <div className="text-lg">
                    {formatCurrency(item.unit_price)}
                  </div>
                </Card.Content>
              </Card>
            );
          })}

          <div className="pt-4">
            <FieldLabel className="mb-0">Allergens</FieldLabel>
            <span className="text-base">
              {allergenList.map((allergen) => allergen).join(', ')}
            </span>
          </div>
          <div className="pt-2">
            <FieldLabel className="mb-0">Total</FieldLabel>
            <span className="text-3xl">{formatCurrency(cartTotal)}</span>
          </div>
        </div>
      }
    />
  );
};
