import { FieldProps } from '../Fields/Field';
import { parse } from '@conform-to/zod';
import { conform } from '@conform-to/react';
import { ZodType } from 'zod';
import { useFormContext } from './Form';
import get from 'lodash/get';

export const useFieldProps = ({ name, type, ...props }: FieldProps) => {
  const { fields, formValues } = useFormContext();

  const { initialError, descriptionId, errorId, ...fieldNameProps } =
    fields[name];

  const formValue = formValues ? get(formValues, name) : undefined;

  const fieldProps: FieldProps<any> & {
    initialerror?: Record<string, string | string[]>;
    descriptionid?: string;
    errorid?: string;
  } = {
    // Note: React was complaining about these props not being valid on the input element
    initialerror: initialError,
    descriptionid: descriptionId,
    errorid: errorId,
    ...fieldNameProps,
    ...conform.input(fieldNameProps),
    ...props,
    onChange: (e) => {
      if (typeof props.onChange === 'function') props.onChange(e);
    },
  };

  if (type === 'checkbox' || type === 'radio') {
    if (fieldProps.checked) {
      fieldProps.checked = fieldProps.checked ?? fieldProps.value === formValue;
    } else {
      fieldProps.defaultChecked =
        fieldProps.defaultChecked ?? fieldProps.value === formValue;
    }
  } else {
    if (!props.defaultValue && formValue) fieldProps.value = formValue;
  }

  return fieldProps;
};

export const selectFormDataFromFormElement = <T = Record<string, any>>(
  formElement: HTMLFormElement,
  schema?: ZodType<T>
): T => {
  if (formElement) {
    const formData = new FormData(formElement);
    if (!schema) return convertFormDataToObject(formData) as T;

    const submission = parse(formData, { schema });
    return submission.payload as T;
  }

  return {} as T;
};

export const convertFormDataToObject = (formData: FormData) => {
  var object = {} as Record<string, any>;
  formData.forEach((value, key) => {
    // Reflect.has in favor of: object.hasOwnProperty(key)
    if (!Reflect.has(object, key)) {
      object[key] = value;
      return;
    }
    if (!Array.isArray(object[key])) {
      object[key] = [object[key]];
    }
    object[key].push(value);
  });
  return object;
};
