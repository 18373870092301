import {
  AllHTMLAttributes,
  ChangeEvent,
  ForwardedRef,
  HTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react';
import { useCombinedFieldProps } from './Field.helpers';
import { FieldWrapper } from './FieldWrapper';
import { FieldLabel, FieldLabelComponent, FieldLabelProps } from './FieldLabel';
import { FieldInput } from './FieldInput';
import { FieldError, FieldErrorComponent, FieldErrorProps } from './FieldError';
import { FieldHelperText } from './FieldHelperText';
import { SelectProps, SelectValueType } from '../Inputs/Select';

interface GeneralFieldProps
  extends Omit<AllHTMLAttributes<HTMLElement>, 'label'> {
  id?: string;
  formId?: string;
  className?: string;
  error?: string;
  helperText?: string;
  optional?: boolean;
  required?: boolean;
  label?: string | ReactNode;
}

export interface InputFieldProps<
  T extends
    | HTMLElement
    | HTMLInputElement
    | HTMLTextAreaElement
    | HTMLSelectElement
> extends GeneralFieldProps {
  inputRef?: ForwardedRef<any>;
  type?: string;
  name: string;
  value?: string | number | readonly string[];
  autoComplete?: string;
  placeholder?: string;
  inputProps?: HTMLAttributes<T> & { ref?: React.RefObject<T> };
  inputComponent?: (props: Partial<AllHTMLAttributes<T>>) => ReactNode;
  defaultValue?: string | number | readonly string[];
  checked?: boolean;
  options?: SelectProps['options'];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}
export interface FieldProps<
  T extends
    | HTMLElement
    | HTMLInputElement
    | HTMLTextAreaElement
    | HTMLSelectElement = HTMLElement
> extends InputFieldProps<T> {
  labelProps?: Omit<FieldLabelProps, 'labelComponent'>;
  labelComponent?: FieldLabelComponent;
  errorProps?: Omit<FieldErrorProps, 'errorComponent'>;
  errorComponent?: FieldErrorComponent;
  helperTextComponent?: FieldLabelComponent;
  helperTextProps?: Omit<FieldErrorProps, 'errorComponent'>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldOptions?: any;
}

export interface SelectFieldProps extends SelectProps {
  label?: ReactNode;
  name: string;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  controlProps?: {
    className?: string;
  };
}

export const Field = forwardRef<HTMLDivElement, FieldProps | SelectFieldProps>(
  (props, ref) => {
    const {
      inputProps,
      labelProps,
      errorProps,
      helperTextProps,
      wrapperProps,
    } = useCombinedFieldProps(props as FieldProps);

    return (
      <FieldWrapper ref={ref} {...wrapperProps}>
        <FieldLabel {...labelProps} />
        <FieldInput {...inputProps} />
        {!props.error && <FieldHelperText {...helperTextProps} />}
        <FieldError {...errorProps} />
      </FieldWrapper>
    );
  }
);
