import { useEffect } from 'react';
import { PersonsSearchTable } from '../components/tables/PersonsSearchTable';
import { useSession } from '../hooks/api/session';
import { useNavigate } from 'react-router-dom';

import { H } from 'highlight.run';

export const Root = () => {
  const {
    session,
    isLoading: sessionLoading,
    isRefetching: sessionRefetching,
  } = useSession();

  const navigate = useNavigate();

  useEffect(() => {
    if (!session && !sessionLoading && !sessionRefetching) navigate('/login');
  }, [session, sessionLoading, sessionRefetching, navigate]);

  useEffect(() => {
    if (!session) return;
    H.identify(`${session.first_name} ${session.last_name}`, {
      id: session.id,
      email: session.email,
      first_name: session.first_name,
      last_name: session.last_name,
    });
  }, [session]);

  return (
    <main>
      {/* <p>TODO: have a default location</p> */}
      <div className="grid gap-4">
        <PersonsSearchTable />
      </div>
    </main>
  );
};
