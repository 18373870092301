import { type NavigateFunction, type Location } from 'react-router-dom';
import { Button } from './Button';
import { FC } from 'react';
import { ArrowLeft } from 'lucide-react';

const pathIncluded = (
  paths: string[],
  location: {
    pathname: string;
    search: string;
    hash: string;
  }
) => {
  return paths.some((path) => location.pathname.endsWith(path));
};

export const BackButton: FC<{
  paths?: string[];
  hidePaths?: string[];
  navigate: NavigateFunction;
  location: Location<any>;
}> = ({ paths, hidePaths, navigate, location }) => {
  const showBackButton = paths ? pathIncluded(paths, location) : true;
  const hideBackButton = hidePaths ? pathIncluded(hidePaths, location) : false;

  if (hideBackButton || !showBackButton) return null;

  return (
    <Button
      className="w-8"
      variant="ghost"
      size="sm"
      onClick={() => navigate(-1)}
    >
      <ArrowLeft />
    </Button>
  );
};
