import { HTMLAttributes, forwardRef } from 'react';
import { cn } from '../../util';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  unstyled?: boolean;
  Header?: typeof CardHeader;
  Title?: typeof CardTitle;
  Description?: typeof CardDescription;
  Content?: typeof CardContent;
  Footer?: typeof CardFooter;
  Actions?: typeof CardActions;
}

interface CardComponent extends React.ForwardRefExoticComponent<CardProps> {
  Header: typeof CardHeader;
  Title: typeof CardTitle;
  Description: typeof CardDescription;
  Content: typeof CardContent;
  Footer: typeof CardFooter;
  Actions: typeof CardActions;
}

const Card = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { unstyled?: boolean }
>(({ className, unstyled, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'bg-card text-card-foreground max-w-[100vw]',
      !unstyled ? 'rounded-lg border shadow-sm' : '',
      className
    )}
    {...props}
  />
)) as CardComponent;
Card.displayName = 'Card';

const CardHeader = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'flex items-center px-3 py-6 sm:p-6 max-x-full overflow-x-auto',
        className
      )}
      {...props}
    />
  )
);
CardHeader.displayName = 'CardHeader';

const CardTitle = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      'text-xl font-semibold leading-none tracking-tight',
      className
    )}
    {...props}
  />
));
CardTitle.displayName = 'CardTitle';

const CardDescription = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
CardDescription.displayName = 'CardDescription';

const CardContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('px-3 pb-6 sm:px-6 pt-0', className)}
      {...props}
    />
  )
);
CardContent.displayName = 'CardContent';

const CardFooter = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(' flex items-center px-3 pb-6 sm:px-6 pt-0', className)}
      {...props}
    />
  )
);
CardFooter.displayName = 'CardFooter';

const CardActions = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'w-full flex items-center justify-end gap-4 pt-4',
        className
      )}
      {...props}
    />
  )
);
CardActions.displayName = 'CardActions';

Card.Header = CardHeader;
Card.Title = CardTitle;
Card.Description = CardDescription;
Card.Content = CardContent;
Card.Footer = CardFooter;
Card.Actions = CardActions;

export { Card };
