import { HTMLAttributes, forwardRef, useRef } from 'react';
import { FieldWrapper } from '../Fields/FieldWrapper';
import { FieldError } from '../Fields/FieldError';
import { useCombinedFieldProps } from '../Fields/Field.helpers';
import { FieldProps } from '../Fields/Field';
import { FieldRadio } from './Fields';
import { FieldLabel, FieldLabelProps } from '../Fields/FieldLabel';
import { useFormContext } from './Form';
import { RadioGroup } from '../Inputs/radio-group';
import { RadioGroupProps } from '@radix-ui/react-radio-group';
import get from 'lodash/get';
import setWith from 'lodash/setWith';
import { cn } from '../../util';

interface Radio {
  id?: string;
  name?: string;
  label: string;
  labelProps?: FieldLabelProps;
  value?: string;
}

export const FieldRadioGroup = forwardRef<
  HTMLDivElement,
  FieldProps<HTMLInputElement> & {
    innerWrapperProps?: Omit<
      HTMLAttributes<HTMLDivElement>,
      'defaultValue' | 'dir'
    >;
    radios?: Radio[];
    radioGroupProps?: RadioGroupProps;
  }
>(({ radios, innerWrapperProps, radioGroupProps, children, ...props }, ref) => {
  const { fields, formValues, updateFormValues } = useFormContext();

  const field = get(fields, props.name) || ({} as Record<string, any>);
  const value = get(formValues, props.name);
  const error = field.error;

  const {
    wrapperProps,
    labelProps,
    inputProps: { defaultChecked, ...inputProps },
    errorProps,
  } = useCombinedFieldProps({ ...props, error });

  return (
    <FieldWrapper ref={ref} {...wrapperProps}>
      <FieldLabel className="text-sm font-bold" {...labelProps} />

      <RadioGroup
        className={cn('radio-group', innerWrapperProps?.className)}
        name={inputProps.name}
        {...innerWrapperProps}
        {...radioGroupProps}
        value={value === undefined ? field.defaultValue || '' : value}
        onValueChange={(updatedValue) => {
          setWith(formValues, inputProps.name, updatedValue, Object);
          updateFormValues(formValues);
        }}
      >
        {children
          ? children
          : radios?.map((radio) => {
              const radioId = radio.id || `${props.name}-${radio.value}`;

              return (
                <FieldRadio
                  key={radioId}
                  {...inputProps}
                  id={radioId}
                  labelProps={{
                    ...labelProps,
                    ...radio.labelProps,
                    htmlFor: radioId, // Note: this id is generated above, so we need to overwrite the id coming from the useCombinedFieldProps function
                  }}
                  label={radio.label}
                  value={radio.value || 'on'}
                  name={props.name}
                  errorComponent={() => null}
                />
              );
            })}
      </RadioGroup>

      <FieldError {...errorProps} />
    </FieldWrapper>
  );
});
