import { FC, HTMLAttributes, ReactElement } from 'react';
import { cn } from '../../util';

export type FieldErrorComponent = (
  errorProps: Omit<FieldErrorProps, 'errorComponent' | 'name'>
) => ReactElement<FieldErrorProps, FC> | null;

export interface FieldErrorProps extends HTMLAttributes<HTMLDivElement> {
  name?: string;
  error?: string;
  errorComponent?: FieldErrorComponent;
}

export const FieldError: FC<FieldErrorProps> = ({
  name,
  className,
  errorComponent,
  ...props
}) => {
  if (!props.error || props.error === 'undefined') return null;

  if (errorComponent) return errorComponent(props);

  return (
    <div
      className={cn('field__error mt-2 text-sm text-red-600', className)}
      {...props}
    >
      {props.error}
    </div>
  );
};
