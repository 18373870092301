import { PersonCard } from '@components/Cards/PersonCard';
import { FC, useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  TableState,
  PaginationState,
  OnChangeFn,
} from '@tanstack/react-table';

import { cn } from '@components/util/index';
import { DataTablePagination } from '@components/Table/data-table-pagination';
import { Table, TableBody, TableCell, TableRow } from '@components/Table/table';
import { Field } from '@components/Fields/Field';
import Fuse from 'fuse.js';
import { Button } from '@components/Buttons/Button';
import { ArrowRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { POSPerson } from '@components/util/types/pos-person';
import { useCart } from '../providers/CartProvider';
import { usePreorders } from '../../hooks/api/preorders';
import { useSelectedLocationId } from '../../hooks/selectedLocation';
import { usePersonsByLocation } from '../../hooks/api/personsByLocation';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  className?: string;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onSortingChange?: OnChangeFn<SortingState>;
  state?: Partial<TableState>;
}

const columns: ColumnDef<POSPerson>[] = [
  {
    id: 'person',
    accessorFn: (row) => row,
    cell: ({ row }) => {
      const cart = useCart();
      const navigate = useNavigate();
      return (
        <PersonCard
          person={row.original}
          accountBalance={row.original.user.account_balance?.balance || 0}
          renderActions={
            <>
              <div className="flex-1" />
              <Button
                size="sm"
                variant="outline"
                className="gap-2 absolute right-4 top-4"
                onClick={() => {
                  cart.setPerson(row.original);
                  navigate(`/checkout`);
                }}
              >
                Checkout <ArrowRight size={16} />
              </Button>
            </>
          }
        />
      );
    },
  },
];

function DataTable<TData, TValue>({
  columns,
  data,
  className,
  state,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state,
    manualPagination: false,
  });

  return (
    <>
      <div className={cn(className)}>
        <Table>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  className="border-none"
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell className="m-0 p-0 pb-4" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <DataTablePagination table={table} totalRecords={data.length || 1} />
    </>
  );
}

export const PersonsSearchTable: FC<{}> = () => {
  const [search, setSearch] = useState<string>('');
  const { setPerson } = useCart();
  const navigate = useNavigate();
  const { selectedLocationId } = useSelectedLocationId();
  const { persons } = usePersonsByLocation(selectedLocationId);

  const fuse = new Fuse(persons, {
    keys: ['first_name', 'last_name', 'nickname', 'grade', 'school_id'],
  });

  const data = fuse.search(search).map((result) => result.item);

  return (
    <div className="grid gap-4">
      <div className="flex gap-2 items-center">
        <Field
          name="search"
          className="flex-1"
          placeholder="Search by name or student ID..."
          autoComplete="off"
          onChange={(e) =>
            setSearch((e.target as HTMLInputElement).value || '')
          }
        />
        <Button
          className="bg-primary-100"
          onClick={() => {
            setPerson(undefined);
            navigate('/checkout');
          }}
        >
          Checkout Guest
        </Button>
      </div>

      <DataTable columns={columns} data={!search ? persons : data} />
    </div>
  );
};
