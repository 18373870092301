import { Card } from '@components/Cards/Card';
import { useLocations } from '../hooks/api/location';
import { useSelectedLocationId } from '../hooks/selectedLocation';
import { FieldSelect } from '@components/Fields/FieldSelect';
import { OptionType } from '@components/Inputs/Select';

export const DeviceSettings = () => {
  const { locations } = useLocations();

  const { selectedLocationId, setSelectedLocationId } = useSelectedLocationId();
  const locationOptions = locations?.map((location) => ({
    value: location.id,
    label: location.name,
  }));

  return (
    <Card>
      <Card.Header>
        <Card.Title>Device Settings</Card.Title>
      </Card.Header>
      <Card.Content>
        <FieldSelect
          label="Device Location"
          name="selected_location"
          options={locationOptions}
          value={selectedLocationId}
          placeholder="Select a location..."
          onChange={(value: string) => setSelectedLocationId(value)}
        />
      </Card.Content>
    </Card>
  );
};
