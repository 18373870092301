import { useQuery } from '@tanstack/react-query';
import { client } from '../../client';
import { POSPerson } from '@components/util/types/pos-person';
import { usePreorders } from './preorders';

export const usePersonsByLocation = (location?: string) => {
  const { data: preorderData } = usePreorders();

  const { data, ...rest } = useQuery<{
    persons: POSPerson[];
  }>(['persons', location], () => {
    if (!location) return Promise.resolve({ persons: [] });
    return client.listPersonsByLocation(location);
  });

  const persons =
    data?.persons.map((person) => {
      const preorder = preorderData.preorders.find(
        (preorder) => preorder.edu_person_id === person.id
      );
      return {
        ...person,
        preorder,
      };
    }) || [];

  return {
    persons,
    ...rest,
  };
};
