import { useQuery } from '@tanstack/react-query';
import { client } from '../../client';
import { DateTime } from 'luxon';

export const useMenuProducts = ({
  location_id,
  date,
}: {
  location_id: string;
  date?: string;
}) => {
  date = date || DateTime.local().toFormat('yyyy-MM-dd');
  const { data, ...rest } = useQuery(['products'], () =>
    client.listProducts({ location_id, date })
  );

  return {
    products: data?.products || [],
    ...rest,
  };
};
