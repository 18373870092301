import { FC, PropsWithChildren } from 'react';
import { CenteredLogoHeader } from '@components/nibll/headers/CenteredLogoHeader';

export const BasicCenteredLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="bg-slate-50 min-h-screen">
      <CenteredLogoHeader />
      <div className="container mx-auto py-24">{children}</div>
    </div>
  );
};
