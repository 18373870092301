import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import './index.css';
import { RootLayout } from './layouts/RootLayout';
import { Root } from './routes/root.tsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Login } from './routes/login.tsx';
import { Checkout } from './routes/checkout.tsx';
import { GlobalProviders } from '@components/Providers/GlobalProviders.tsx';
import { H } from 'highlight.run';
import { ErrorBoundary } from '@highlight-run/react';
import { config } from './config.ts';
import { SquareCallback } from './routes/square-callback.tsx';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { DeviceSettings } from './routes/device-settings.tsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

H.init(config.HIGHLIGHT_PROJECT_ID, {
  tracingOrigins: true,
  environment: config.ENVIRONMENT,
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
    urlBlocklist: [],
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 1, // 1 hour
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  throttleTime: 1000,
});

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Root />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/device-settings" element={<DeviceSettings />} />
        <Route path="/square-callback" element={<SquareCallback />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </>
  )
);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <GlobalProviders>
          <ToastContainer hideProgressBar limit={1} autoClose={2000} />
          <RouterProvider router={router} />
        </GlobalProviders>
      </QueryClientProvider>
    </ErrorBoundary>
  </StrictMode>
);
