import { POSPerson } from '@components/util/types/pos-person';
import { POSProduct } from '@components/util/types/pos-product';
import { Location, Discount } from 'database';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { config } from '../../config';

export interface CartItem {
  id: string | number;
  product?: POSProduct;
  quantity: number;
  mise_dish_id?: number;
  mise_menu_type_id?: number;
  mise_menu_item_type_id?: number;
  unit_price: number;
  name: string;
  discount?: Discount;
}

export interface Cart {
  items: CartItem[];
  person?: POSPerson;
  location?: Location;
}

export interface SquarePaymentData {
  amount_money: {
    amount: string;
    currency_code: string;
  };
  callback_url: string;
  client_id: string;
  version: string;
  notes?: string;
  options: {
    supported_tender_types: (
      | 'CREDIT_CARD'
      | 'CASH'
      | 'OTHER'
      | 'SQUARE_GIFT_CARD'
      | 'CARD_ON_FILE'
    )[];
  };
}

export const defaultCart: Cart = {
  items: [],
};

export interface CartContext {
  cart: Cart;
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
}

export const CartContext = React.createContext<CartContext>({
  cart: defaultCart,
  setCart: () => {
    return;
  },
});

export interface AddProductOptions {
  free?: boolean;
}

export const useCart = () => {
  const { cart, setCart } = useContext(CartContext);
  const setPerson = (person: POSPerson) => {
    setCart({ ...cart, person });
  };

  const addProduct = (product: POSProduct, options?: AddProductOptions) => {
    const { free } = options || {};

    let unit_price = product.computed_price || product.unit_price;
    if (free) unit_price = 0;

    setCart({
      ...cart,
      items: [
        ...cart.items,
        {
          id: product.id,
          product: product,
          mise_dish_id: product.mise_dish_id,
          mise_menu_type_id: product.type.menu_type_id,
          mise_menu_item_type_id: product.type.id,
          quantity: 1,
          unit_price,
          discount: product.discount,
          name: `${product.name} (${product.type.name})`,
        },
      ],
    });
  };

  const addCustomItem = (item: CartItem) => {
    setCart({
      ...cart,
      items: [...cart.items, item],
    });
  };

  const removeItem = (index: number) => {
    const newItems = [...cart.items];
    newItems.splice(index, 1);
    setCart({ ...cart, items: newItems });
  };

  const clearCart = () => {
    setCart(defaultCart);
  };

  const getTotal = () => {
    return cart.items.reduce((acc, item) => {
      return acc + item.unit_price;
    }, 0);
  };

  const getSquarePaymentUrl = () => {
    const quantityText = (item: CartItem) =>
      item.quantity > 1 ? ` (${item.quantity})` : '';
    const notes = cart.items
      .map((item) => `${item.name}${quantityText}`)
      .join(', ');

    const data: SquarePaymentData = {
      amount_money: {
        amount: getTotal().toString(),
        currency_code: 'USD',
      },
      callback_url: config.SQUARE_CALLBACK_URL,
      client_id: config.SQUARE_CLIENT_ID,
      version: '1.3',
      notes,
      options: {
        supported_tender_types: ['CREDIT_CARD', 'OTHER', 'CARD_ON_FILE'],
      },
    };

    return (
      'square-commerce-v1://payment/create?data=' +
      encodeURIComponent(JSON.stringify(data))
    );
  };

  return {
    cart,
    addProduct,
    addCustomItem,
    removeItem,
    clearCart,
    setPerson,
    getTotal,
    getSquarePaymentUrl,
  };
};

export const CartProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const getInitialCart = () => {
    const storedCartSessionString = window.localStorage.getItem('cart');
    const storedCartSession = storedCartSessionString
      ? JSON.parse(storedCartSessionString)
      : null;

    if (storedCartSession) return storedCartSession;

    return defaultCart;
  };

  const [cart, setCart] = useState<Cart>(getInitialCart());

  useEffect(() => {
    window.localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart, setCart }}>
      {children}
    </CartContext.Provider>
  );
};
