import { InputFieldProps } from './Field';
import { Input } from '../Inputs/Input';

export const FieldInput = <T extends HTMLElement>({
  inputComponent,
  inputRef,
  label,
  ...inputProps
}: InputFieldProps<T>) => {
  if (inputComponent) {
    return <>{inputComponent(inputProps as any)}</>;
  }

  return (
    <Input
      ref={inputRef as React.ForwardedRef<HTMLInputElement>}
      {...(inputProps as unknown as React.InputHTMLAttributes<HTMLElement>)}
    />
  );
};
