export const allGrades = [
  {
    label: 'Pre-School',
    value: 'PS',
  },
  {
    label: 'Pre-K',
    value: 'PK',
  },
  {
    label: 'Junior Kindergarten',
    value: 'JK',
  },
  {
    label: 'Kindergarten',
    value: 'K',
  },
  {
    label: 'Foundations 1-6',
    value: 'F1-6',
  },
  {
    label: '1st Grade',
    value: '1',
  },
  {
    label: '2nd Grade',
    value: '2',
  },
  {
    label: '3rd Grade',
    value: '3',
  },
  {
    label: '4th Grade',
    value: '4',
  },
  {
    label: '5th Grade',
    value: '5',
  },
  {
    label: '6th Grade',
    value: '6',
  },
  {
    label: '7th Grade',
    value: '7',
  },
  {
    label: '8th Grade',
    value: '8',
  },
  {
    label: '9th Grade',
    value: '9',
  },
  {
    label: '10th Grade',
    value: '10',
  },
  {
    label: '11th Grade',
    value: '11',
  },
  {
    label: '12th Grade',
    value: '12',
  },
  {
    label: 'Poppy Part-Time',
    value: 'PPT',
  },
  {
    label: 'Poppy 1',
    value: 'P1',
  },
  {
    label: 'Poppy 2',
    value: 'P2',
  },
  {
    label: 'Johnny 1',
    value: 'J1',
  },
  {
    label: 'Johnny 2',
    value: 'J2',
  },
  {
    label: 'Johnny 3',
    value: 'J3',
  },
  {
    label: 'Ruby 1',
    value: 'R1',
  },
  {
    label: 'Ruby 2',
    value: 'R2',
  },
  {
    label: 'Bluebell',
    value: 'B',
  },
] as const;

export const piperGrades = [
  {
    label: 'Poppy Part-Time',
    value: 'PPT',
  },
  {
    label: 'Poppy 1',
    value: 'P1',
  },
  {
    label: 'Poppy 2',
    value: 'P2',
  },
  {
    label: 'Johnny 1',
    value: 'J1',
  },
  {
    label: 'Johnny 2',
    value: 'J2',
  },
  {
    label: 'Johnny 3',
    value: 'J3',
  },
  {
    label: 'Ruby 1',
    value: 'R1',
  },
  {
    label: 'Ruby 2',
    value: 'R2',
  },
  {
    label: 'Bluebell',
    value: 'B',
  },
] as const;

export const fairmontGrades = [
  {
    label: 'Pre-School',
    value: 'PS',
  },
  {
    label: 'Pre-K',
    value: 'PK',
  },
  {
    label: 'Junior Kindergarten',
    value: 'JK',
  },
  {
    label: 'Kindergarten',
    value: 'K',
  },
  {
    label: 'Foundations 1-6',
    value: 'F1-6',
  },
  {
    label: '1st Grade',
    value: '1',
  },
  {
    label: '2nd Grade',
    value: '2',
  },
  {
    label: '3rd Grade',
    value: '3',
  },
  {
    label: '4th Grade',
    value: '4',
  },
  {
    label: '5th Grade',
    value: '5',
  },
  {
    label: '6th Grade',
    value: '6',
  },
  {
    label: '7th Grade',
    value: '7',
  },
  {
    label: '8th Grade',
    value: '8',
  },
  {
    label: '9th Grade',
    value: '9',
  },
  {
    label: '10th Grade',
    value: '10',
  },
  {
    label: '11th Grade',
    value: '11',
  },
  {
    label: '12th Grade',
    value: '12',
  },
] as const;

export const grades = [
  {
    label: 'Pre-School',
    value: 'PS',
  },
  {
    label: 'Pre-K',
    value: 'PK',
  },
  {
    label: 'Junior Kindergarten',
    value: 'JK',
  },
  {
    label: 'Kindergarten',
    value: 'K',
  },
  {
    label: '1st Grade',
    value: '1',
  },
  {
    label: '2nd Grade',
    value: '2',
  },
  {
    label: '3rd Grade',
    value: '3',
  },
  {
    label: '4th Grade',
    value: '4',
  },
  {
    label: '5th Grade',
    value: '5',
  },
  {
    label: '6th Grade',
    value: '6',
  },
  {
    label: '7th Grade',
    value: '7',
  },
  {
    label: '8th Grade',
    value: '8',
  },
  {
    label: '9th Grade',
    value: '9',
  },
  {
    label: '10th Grade',
    value: '10',
  },
  {
    label: '11th Grade',
    value: '11',
  },
  {
    label: '12th Grade',
    value: '12',
  },
] as const;

export type GradeValues = (typeof allGrades)[number]['value'];

export const selectGradeLabel = (value: string | null) => {
  if (!value) return null;
  const grade = allGrades.find((g) => g.value === value);
  return grade ? grade.label : null;
};

export const selectGradesByLocation = (miseClientId?: number | null) => {
  switch (miseClientId) {
    case 632: // Anaheim Hills
      return fairmontGrades.filter((o) => !['9', '10', '11', '12'].includes(o.value));
    case 603: // SJC
      return fairmontGrades;
    case 628: // Historic Anaheim
      return fairmontGrades.filter((o) => !['9', '10', '11', '12'].includes(o.value));
    case 616: // Prep High
      return fairmontGrades.filter((o) => ['9', '10', '11', '12'].includes(o.value));
    case 653: // Boarding
      return fairmontGrades.filter((o) => ['9', '10', '11', '12'].includes(o.value));
    case 668: // Red Apple
      return grades.filter((o) => !['PS', 'PK', 'JK', 'F1-6'].includes(o.value));
    case 662: // Red Apple Dev
      return grades.filter((o) => !['PS', 'PK', 'JK'].includes(o.value));
    case 663: // Piper dev
      return piperGrades;
    case 703: // Piper
      return piperGrades;
    case 664: // aHa dev
      return grades.filter((o) => !['PS', 'PK', 'JK'].includes(o.value));
    case 704: // aHa
      return grades.filter((o) => !['PS', 'PK', 'JK'].includes(o.value));
    case 665: // OCathletes dev
      return grades.filter((o) => !['PS', 'PK', 'JK'].includes(o.value));
    case 715: // OCathletes
      return grades.filter((o) => !['PS', 'PK', 'JK'].includes(o.value));
    default:
      return grades;
  }
};

export const selectDefaultPortionForGrade: (grade: GradeValues) => 'SMALL' | 'MEDIUM' | 'LARGE' = (grade) => {
  switch (grade) {
    case 'PS':
    case 'PK':
    case 'JK':
    case 'K':
    case '1':
    case 'PPT':
    case 'P1':
    case 'P2':
    case 'J1':
    case 'J2':
    case 'J3':
    case 'R1':
    case 'R2':
    case 'B':
      return 'SMALL';
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
      return 'MEDIUM';
    case '10':
    case '11':
    case '12':
      return 'LARGE';
    default:
      return 'MEDIUM';
  }
};
