import { FC } from 'react';
import { Card } from './Card';
import { selectGradeLabel } from '../../util/data/grades';
import {
  selectAllergens,
  selectDietaryPreferences,
} from '../../util/data/allergens';
import { FieldLabel } from '../Fields/FieldLabel';
import {
  EmployeePortionSizeNameMapByLocation,
  PortionSizeNameMapByLocation,
} from '../../util/data/portion-sizing';
import { formatCurrency } from '../../util/currency';
import { Briefcase, Cake, GraduationCap, Hash, MapPin } from 'lucide-react';
import { DateTime } from 'luxon';
import { POSPerson, Location } from '../../util/types/pos-person';
import { cn } from '../../util';

interface PersonCardProps {
  className?: string;
  location?: Location | null;
  person: POSPerson;
  renderTitle?: React.ReactNode;
  renderActions?: React.ReactNode;
  accountBalance?: number;
  isPOS?: boolean;
}

export const PersonCard: FC<PersonCardProps> = ({
  className,
  person,
  location,
  renderTitle,
  renderActions,
  accountBalance,
  isPOS,
}) => {
  const name = person.nickname
    ? `${person.nickname} (${person.first_name}) ${person.last_name}`
    : `${person.first_name} ${person.last_name}`;

  const dietaryPreferences = selectDietaryPreferences(
    person.dietary_preferences
  );
  const allergenList = selectAllergens(person.allergens);

  const portionSize = person.portion_sizing;

  const preorderItems = (person.preorder?.items || [])
    .map((item) => item.dish.name)
    .join(', ');

  const getPortionSizeNames = (
    type: string | null,
    clientId: number | undefined | null
  ) => {
    const map =
      type === 'STAFF'
        ? EmployeePortionSizeNameMapByLocation
        : PortionSizeNameMapByLocation;

    return clientId !== undefined &&
      clientId !== null &&
      map.hasOwnProperty(clientId)
      ? map[clientId]
      : map[0];
  };

  let portionSizeNames = getPortionSizeNames(
    person.type,
    location?.mise_client_id
  );

  const badAccountBalance =
    typeof accountBalance === 'number' && accountBalance <= 0;

  return (
    <Card className={className}>
      <Card.Header className="items-end gap-4 flex-wrap-reverse relative pb-3">
        <Card.Title className="flex flex-col gap-1">
          <div className="flex gap-2.5">{renderTitle ? renderTitle : name}</div>
        </Card.Title>
        {renderActions}
      </Card.Header>
      <Card.Content className="flex flex-col gap-2">
        <p className="text-sm font-normal flex flex-wrap items-center gap-2">
          {location && (
            <span className="flex gap-0.5 items-center">
              <MapPin height={12} />
              {location?.name}
            </span>
          )}
          {person.type === 'STAFF' ? (
            <span className="flex gap-0.5 items-center">
              <Briefcase height={12} />
              Employee
            </span>
          ) : (
            <span className="flex gap-0.5 items-center">
              <GraduationCap height={12} />
              {selectGradeLabel(person.grade)}
            </span>
          )}
        </p>
        <p className="text-sm font-normal flex gap-2 items-center">
          {person.school_id && (
            <span className="flex gap-0.5 items-center">
              <Hash height={12} />
              {person.school_id}
            </span>
          )}

          {person.dob && (
            <span className="flex gap-0.5 items-center">
              <Cake height={12} />
              {DateTime.fromISO(person.dob, { zone: 'utc' }).toFormat(
                'M/d/yyyy'
              )}
            </span>
          )}
        </p>
        {portionSize && (
          <div>
            <FieldLabel className="mb-0">Portion Size</FieldLabel>
            <p className="text-sm">{portionSizeNames[portionSize]}</p>
          </div>
        )}
        {dietaryPreferences.length > 0 && (
          <div>
            <FieldLabel className="mb-0">Dietary Preferences</FieldLabel>
            <p className="text-sm">
              {dietaryPreferences.map(({ label }) => label).join(', ')}
            </p>
          </div>
        )}
        {allergenList.length > 0 && (
          <div>
            <FieldLabel className="mb-0">Allergens</FieldLabel>
            <p className="text-sm">
              {allergenList.map((a) => a.label).join(', ')}
            </p>
          </div>
        )}
        {person.notes && (
          <div
            className={cn({
              'text-red-800 font-bold border rounded-sm border-red-200 p-2 -mx-2':
                isPOS,
            })}
          >
            <FieldLabel className="mb-0">Notes</FieldLabel>
            <p className="text-sm">{person.notes}</p>
          </div>
        )}
        {typeof accountBalance === 'number' && (
          <div className={cn({ 'text-red-600': badAccountBalance })}>
            <FieldLabel className="mb-0 !text-inherit">
              Account Balance
            </FieldLabel>
            <p className="text-sm">{formatCurrency(accountBalance)}</p>
          </div>
        )}
        {preorderItems.length > 0 && (
          <div>
            <FieldLabel className="mb-0">Preorder</FieldLabel>
            <p className="text-sm">{preorderItems}</p>
          </div>
        )}
        {!!person.discount_plans && person.discount_plans?.length > 0 && (
          <div>
            <FieldLabel className="mb-0">Discount Plans</FieldLabel>
            <p className="text-sm">
              {person.discount_plans
                .map((discountPlan) => discountPlan.name)
                .join(', ')}
            </p>
          </div>
        )}
      </Card.Content>
    </Card>
  );
};
