import * as React from 'react';
import { cn } from '../../util';
import { defaultInputClasses } from './Input.helpers';
import { FieldProps } from '../Fields/Field';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: FieldProps['error'];
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, error, ...props }, ref) => {
    return (
      <textarea
        key={props.defaultValue?.toString()}
        className={cn(defaultInputClasses(error), className)}
        rows={4}
        ref={ref}
        {...props}
      />
    );
  }
);
