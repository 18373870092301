import {
  ElementType,
  FormEventHandler,
  forwardRef,
  InputHTMLAttributes,
  RefObject,
} from 'react';
import { Checkbox } from './checkbox';
import { RadioGroupItem } from './radio-group';
import { cn } from '../../util';
import { LucideIcon } from 'lucide-react';

export interface InputRadioCheckboxProps
  extends Omit<
    InputHTMLAttributes<HTMLButtonElement | HTMLInputElement>,
    'onChange'
  > {
  name: string;
  error?: string | null;
  value: string;
  onChange: FormEventHandler<HTMLButtonElement | HTMLInputElement>;
  icon?: ElementType | LucideIcon;
}

export const InputRadioCheckbox = forwardRef<
  HTMLButtonElement | HTMLInputElement,
  InputRadioCheckboxProps
>(({ className, error, defaultValue, children, type, ...props }, ref) => {
  const Comp = type === 'radio' ? RadioGroupItem : Checkbox;

  return (
    <Comp
      key={defaultValue?.toString()}
      ref={ref as any}
      id={props.id || props.name}
      {...props}
      className={cn(
        'h-5 w-5 text-primary-800 focus:ring-primary-500',
        { 'border-red-600': !!error },
        className
      )}
    />
  );
});
