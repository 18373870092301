import { useQuery } from '@tanstack/react-query';
import { client } from '../../client';

export const usePreorders = () => {
  const { data, ...rest } = useQuery(
    ['preorders'],
    () => client.listPreorders(),
    {
      staleTime: 60 * 1000,
    }
  );

  return {
    data: data?.preorders || {
      count: 0,
      preorders: [],
    },
    ...rest,
  };
};
