import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { type LucideIcon } from 'lucide-react';
import { ElementType, HTMLProps, forwardRef } from 'react';
const Checkbox = forwardRef<
  HTMLInputElement,
  HTMLProps<HTMLInputElement> & {
    className?: string;
    icon?: ElementType | LucideIcon;
  }
>(({ className, onChange, icon, ...props }, ref) => {
  if (typeof onChange === 'function') {
    props.onClick = onChange;
  }

  return (
    <input
      type="checkbox"
      className="peer mx-2 h-4 w-4 shrink-0 rounded-sm border border-primary-800 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary-800 data-[state=checked]:text-primary-foreground accent-primary"
      ref={ref as any}
      {...props}
    />
  );

  // const Icon = icon || Check;

  // return (
  //   <CheckboxPrimitive.Root
  //     ref={ref}
  //     className={cn(
  //       'peer h-4 w-4 shrink-0 rounded-sm border border-primary-800 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary-800 data-[state=checked]:text-primary-foreground',
  //       className
  //     )}
  //     {...props}
  //     checked={checked}
  //     onCheckedChange={setChecked}
  //   >
  //     <CheckboxPrimitive.Indicator
  //       className={cn('flex items-center justify-center text-current')}
  //     >
  //       <Icon className="h-4 w-4" />
  //     </CheckboxPrimitive.Indicator>
  //   </CheckboxPrimitive.Root>
  // );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;
export { Checkbox };
