import { FC, LabelHTMLAttributes, ReactElement } from 'react';
import { cn } from '../../../util';

export interface FieldLabelComponentProps
  extends LabelHTMLAttributes<HTMLElement> {
  required?: boolean;
  optional?: boolean;
}

export type FieldLabelComponent = (
  labelProps: FieldLabelComponentProps
) => ReactElement<FieldLabelComponentProps, FC>;

export interface FieldLabelProps extends FieldLabelComponentProps {
  labelComponent?: FieldLabelComponent;
}

export const FieldRadioCheckboxLabel: FC<FieldLabelProps> = ({
  className,
  labelComponent,
  children,
  ...props
}) => {
  if (!labelComponent && !children) return null;

  if (labelComponent) return labelComponent({ ...props, children });

  return (
    <div className="flex text-sm">
      <label
        {...props}
        className={cn(
          'field__label text-sm flex items-center font-medium leading-none cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
          className
        )}
      >
        <span className="flex items-center gap-2">{children}</span>
        {props.required && (
          <span className="text-red-600 relative -top-0.5 text-xs">*</span>
        )}

        {props.optional && (
          <span className="font-normal text-gray-500 text-xs relative top-[1px] ml-2">
            Optional
          </span>
        )}
      </label>
    </div>
  );
};
