import { Card } from '@components/Cards/Card';
import type { FC } from 'react';
import { formatCurrency } from '@components/util/currency';
import { allergensOptions, selectAllergens, selectDishAllergensAsOptions } from '@components/util/data/allergens';
import { Button } from '@components/Buttons/Button';
import { X } from 'lucide-react';
import { FieldLabel } from '@components/Fields/FieldLabel';
import { CheckoutConfirmationModal } from './CheckoutConfirmationModal';
import type { POSPerson } from '@components/util/types/pos-person';
import { useCreateOrder } from '../../hooks/api/createOrder';
import { useSession } from '../../hooks/api/session';
import { type CartItem, useCart } from '../providers/CartProvider';
import { Badge } from '@components/Badges/badge';
import { useSelectedLocationId } from '../../hooks/selectedLocation';
import { Alert, AlertDescription, AlertTitle } from '@components/Alerts/Alert';

export const Cart: FC<{
  person?: POSPerson;
  items: CartItem[];
  orderSuccess: () => void;
}> = ({ items = [], person, orderSuccess }) => {
  const { selectedLocationId } = useSelectedLocationId();
  const { mutate, isLoading } = useCreateOrder({
    onSuccess: () => orderSuccess(),
  });
  const { session } = useSession();

  const { removeItem, getSquarePaymentUrl } = useCart();

  const handleCheckoutWithCard = () => {
    if (typeof window === 'undefined') return;
    window.location.href = getSquarePaymentUrl();
  };

  const createOrder = () => {
    mutate({
      person_id: person?.id,
      location_id: selectedLocationId,
      payment_type: 'ACCOUNT_BALANCE',
      cashier_id: session.id,
      items: items.map((item) => ({
        quantity: 1,
        mise_dish_id: item.product.mise_dish_id,
        mise_menu_type_id: item.mise_menu_type_id,
        mise_menu_item_type_id: item.mise_menu_item_type_id,
        name: item.name,
        unit_price: item.unit_price,
      })),
    });
  };

  const allergenList = items.reduce((acc, item) => {
    const productAllergens = item.product?.allergens?.map(
      (productAllergen) =>
        allergensOptions.find((allergen) => allergen.value === productAllergen)?.label || productAllergen
    );
    productAllergens?.forEach((allergen) => {
      if (!acc.includes(allergen)) {
        acc.push(allergen);
      }
    });

    return acc;
  }, [] as string[]);

  const cartTotal = items.reduce((acc, product) => {
    return acc + product.unit_price;
  }, 0);

  const personAllergens = selectAllergens(person?.allergens);

  const allergenOverlap = allergenList
    .filter((allergen) => personAllergens.some((personAllergen) => personAllergen.label === allergen))
    .join(', ');

  const notEnoughFunds = person?.user.account_balance.balance < cartTotal;

  return (
    <Card>
      <Card.Header className="pb-2">
        <Card.Title className="flex gap-2 items-center">
          Cart
          {items.length > 0 && <Badge variant="outline">{items.length}</Badge>}
        </Card.Title>
      </Card.Header>
      {items.length > 0 ? (
        <>
          <Card.Content>
            {items.map((item, index) => {
              const itemType = item.product?.type.name;
              const allergens = selectDishAllergensAsOptions(item.product?.allergens)
                .map((a) => a.label)
                .join(', ');

              return (
                <Card key={item.id} unstyled className="border-b">
                  <Card.Header className="relative !px-0 !pt-2 !pb-2 overflow-x-visible">
                    <Card.Title className="text-sm pr-8 w-full grid">
                      <span>{item.name}</span>
                      <span className="text-xs font-normal">{itemType}</span>
                      <span className="text-xs font-normal">{allergens}</span>
                    </Card.Title>
                    <Button className="h-9 -mr-2.5" size="icon" variant="ghost" onClick={() => removeItem(index)}>
                      <X size="16" color="darkred" />
                    </Button>
                  </Card.Header>
                  <Card.Content className="!px-0 !pb-2">
                    <div className="flex justify-between items-center w-full">
                      <div>{formatCurrency(item.unit_price)}</div>

                      <div className="text-xs font-bold">{item.discount?.name}</div>
                    </div>
                  </Card.Content>
                </Card>
              );
            })}

            <div className="mt-4">
              <FieldLabel className="mb-0">Allergens</FieldLabel>
              <span className="text-sm">
                {selectDishAllergensAsOptions(allergenList)
                  .map((a) => a.label)
                  .join(', ')}
              </span>
            </div>
            <div className="mt-2">
              <FieldLabel className="mb-0">Total</FieldLabel>
              <span className="text-2xl">{formatCurrency(cartTotal)}</span>
            </div>
          </Card.Content>

          <Card.Footer className="pb-4 flex-col gap-4">
            {person && allergenOverlap && (
              <Alert className="border-red-600">
                <AlertTitle>
                  {person.nickname || person.last_name} has allergies to{' '}
                  <span className="text-red-600">{allergenOverlap}</span>
                </AlertTitle>
                <AlertDescription>Please review the items in the cart before proceeding.</AlertDescription>
              </Alert>
            )}

            <Card.Actions className="pt-0 flex-col">
              <CheckoutConfirmationModal
                person={person}
                allergenList={allergenList}
                cartTotal={cartTotal}
                items={items}
                continueLabel="Checkout with card"
                onContinue={handleCheckoutWithCard}
              >
                <Button className="flex-1 w-full">Checkout with card</Button>
              </CheckoutConfirmationModal>

              <CheckoutConfirmationModal
                person={person}
                allergenList={allergenList}
                cartTotal={cartTotal}
                items={items}
                continueLabel="Charge account"
                onContinue={createOrder}
              >
                <Button className="flex-1 w-full" variant={notEnoughFunds ? 'danger' : 'primary'} disabled={isLoading}>
                  {notEnoughFunds ? 'Not enough funds' : 'Charge account'}
                </Button>
              </CheckoutConfirmationModal>
            </Card.Actions>
          </Card.Footer>
        </>
      ) : (
        <Card.Content>
          <div>Cart is empty</div>
        </Card.Content>
      )}
    </Card>
  );
};
