import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from './AlertDialog';
import { FC, PropsWithChildren, ReactNode } from 'react';

export interface ConfirmationModalProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  title?: ReactNode;
  description?: ReactNode;
  cancelLabel?: ReactNode;
  continueLabel?: ReactNode;
  onContinue?: () => void;
  children?: ReactNode;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  children,
  title,
  description,
  cancelLabel = 'Cancel',
  continueLabel = 'Continue',
  onContinue,
  ...props
}) => {
  return (
    <AlertDialog {...props}>
      {children && <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>}
      <AlertDialogContent className="confirmation-modal pb-0">
        {(title || description) && (
          <AlertDialogHeader>
            {title && <AlertDialogTitle>{title}</AlertDialogTitle>}
            {description && (
              <AlertDialogDescription>{description}</AlertDialogDescription>
            )}
          </AlertDialogHeader>
        )}
        <AlertDialogFooter className="sticky bottom-0 bg-background py-4">
          <AlertDialogCancel>{cancelLabel}</AlertDialogCancel>
          <AlertDialogAction onClick={onContinue}>
            {continueLabel}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
