import { Avatar, AvatarFallback } from '@components/Avatar/Avatar';
import { Button } from '@components/Buttons/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@components/DropdownMenu/dropdown-menu';
import { useLogout } from '../../hooks/api/session';
import { POSSession } from '../../client';
import { Link, useNavigate } from 'react-router-dom';

export const UserNav = ({ session }: { session: POSSession }) => {
  const navigate = useNavigate();
  const { mutate } = useLogout({ onSuccess: () => navigate('/login') });
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="relative h-8 w-8 rounded-full hover:ring-2 ring-slate-400 ring-offset-2"
        >
          <Avatar className="h-8 w-8">
            <AvatarFallback>
              {session.first_name.charAt(0).toUpperCase()}
              {session.last_name.charAt(0).toUpperCase()}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {`${session.first_name} ${session.last_name}`}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {session.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link to="/device-settings">Device Settings</Link>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => mutate()}>Log out</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
