import { cn } from '../../util';
import { FieldProps } from '../Fields/Field';

const baseInputClasses =
  'flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50';

const inputErrorClasses = 'border-red-600 text-red-600 placeholder-red-600';

export const defaultInputClasses = (error: FieldProps['error']) =>
  cn(baseInputClasses, error ? inputErrorClasses : '');
