import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useCart } from '../components/providers/CartProvider';
import { useCreateOrder } from '../hooks/api/createOrder';
import { useEffect, useState } from 'react';
import { useSelectedLocationId } from '../hooks/selectedLocation';
import { useSession } from '../hooks/api/session';
import { Button } from '@components/Buttons/Button';
import { useBroadcastChannel } from '../hooks/useBroadcastChannel';

export const SquareCallback = () => {
  const [searchParams] = useSearchParams();
  const { cart, clearCart } = useCart();
  const { selectedLocationId } = useSelectedLocationId();
  const navigate = useNavigate();
  const { session } = useSession();
  const [error, setError] = useState<string>(null);
  const [success, setSuccess] = useState<string>(null);
  const squareOrderBroadcast = useBroadcastChannel('square-order');
  const { mutateAsync, isLoading } = useCreateOrder({
    onSuccess: () => {
      squareOrderBroadcast('success');
      setSuccess('Order saved successfully! You can now close this tab.');
    },
    onError: (err: any) => {
      setError(
        err?.message ||
          'Payment successful but Unknown error reporting order to NIBLL Admin. Please let someone know.'
      );
    },
  });

  const createOrder = async () => {
    await new Promise((resolve) => setTimeout(resolve, 300));
    const data = searchParams.get('data');
    const parsedData = JSON.parse(data);

    if (parsedData.error_code) {
      setError(parsedData.error_code);
      return;
    }

    if (isLoading) return; // already mutating

    await mutateAsync({
      person_id: cart.person?.id,
      location_id: selectedLocationId,
      payment_type: 'CARD',
      cashier_id: session?.id,
      items: cart.items.map((item) => ({
        quantity: 1,
        mise_dish_id: item.product.mise_dish_id,
        mise_menu_type_id: item.mise_menu_type_id,
        mise_menu_item_type_id: item.mise_menu_item_type_id,
        name: item.name,
        unit_price: item.unit_price,
      })),
    });
  };

  useEffect(() => {
    createOrder();
  }, []);

  if (!error && !success) return <div>Processing!</div>;

  if (success)
    return <div className="font-lg font-bold my-4 mx-auto">{success}</div>;

  if (error)
    return (
      <div className="flex">
        <h2 className="text-lg my-3">Something went wrong</h2>
        <p className="text-md text-red-600 mb-3">{error}</p>

        <div>
          <Button variant="primary" asChild>
            <Link to="/checkout">Return to Cart</Link>
          </Button>
          <Button
            variant="accent"
            onClick={() => {
              clearCart();
              navigate('/');
            }}
          >
            Clear Cart and Start Over
          </Button>
        </div>
      </div>
    );
};
