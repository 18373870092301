import { forwardRef } from 'react';
import { Field, FieldProps, InputFieldProps } from './Field';
import { TextArea } from '../Inputs/TextArea';

export const FieldTextArea = forwardRef<
  HTMLDivElement,
  FieldProps<HTMLTextAreaElement>
>((props, ref) => (
  <Field
    ref={ref}
    // @ts-ignore
    inputComponent={(props: InputFieldProps<HTMLTextAreaElement>) => (
      // @ts-ignore
      <TextArea {...props} />
    )}
    {...props}
  />
));
