import { forwardRef, HTMLAttributes } from 'react';
import { cn } from '../../util';

export interface FieldWrapperProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  type?: string;
  error?: string;
}

export const FieldWrapper = forwardRef<HTMLDivElement, FieldWrapperProps>(
  ({ type = 'text', name, className, error, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'field',
        {
          [`field--${type}`]: type,
          [`field--${name}`]: name,
          'field--has-error': !!error,
        },
        className
      )}
      {...props}
    />
  )
);
