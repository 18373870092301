import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { buildOptions } from '.';
import { LoginBody, POSSessionResponse, client } from '../../client';

export const useSession = () => {
  const { data, refetch, ...rest } = useQuery(['session'], () =>
    client.getSession()
  );

  return {
    session: data?.session,
    authenticated: !!data?.session,
    refetch,
    ...rest,
  };
};

export const useLogin = (
  options?: UseMutationOptions<POSSessionResponse, null, LoginBody, undefined>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: LoginBody) => client.login(body),
    buildOptions(queryClient, [['session']], options)
  );
};

export const useLogout = (
  options?: UseMutationOptions<unknown, null, void, undefined>
) => {
  const queryClient = useQueryClient();

  return useMutation(async () => {
    await client.logout();
  }, buildOptions(queryClient, [['session']], options));
};
