import { cn } from '../../util';
import { FieldProps, InputFieldProps } from './Field';
import { FieldErrorProps } from './FieldError';
import { FieldHelperTextProps } from './FieldHelperText';
import { FieldLabelProps } from './FieldLabel';
import { FieldWrapperProps } from './FieldWrapper';

export const useCombinedFieldProps = <T extends HTMLElement>(
  props: FieldProps<T>
) => {
  const {
    id,
    type,
    name,
    label,
    optional,
    error,
    labelComponent,
    errorComponent,
    helperText,
    helperTextComponent,
    helperTextProps,
    className,
    labelProps,
    inputProps,
    errorProps,
    ...restProps
  } = props;

  const inputId = id || name;

  const combinedWrapperProps: FieldWrapperProps = {
    name,
    className,
    type,
    error,
  };
  if (type === 'hidden')
    combinedWrapperProps.className = cn(
      combinedWrapperProps.className,
      'hidden'
    );

  const combinedInputProps = {
    ...inputProps,
    ...restProps,
    id: inputId,
    type,
    name,
    error,
  } as InputFieldProps<T>;

  const combinedLabelProps: FieldLabelProps = {
    htmlFor: inputId,
    children: label,
    labelComponent,
    optional,
    // Note: you can show a field as required by passing it in labelProps as { required: true }
    ...labelProps,
  };

  const combinedErrorProps: FieldErrorProps = {
    name,
    error,
    errorComponent,
    ...errorProps,
  };

  const combinedHelperTextProps: FieldHelperTextProps = {
    helperText,
    helperTextComponent,
    ...helperTextProps,
  };

  return {
    inputProps: combinedInputProps,
    labelProps: combinedLabelProps,
    errorProps: combinedErrorProps,
    wrapperProps: combinedWrapperProps,
    helperTextProps: combinedHelperTextProps,
  };
};
